import React from 'react';
import { Animated, View, Image, Text, TouchableHighlight, Dimensions, ImageBackground } from 'react-native';
import Constants from 'expo-constants';
import AnimateNumber from './AnimateNumber';
import _ from 'lodash';
import utils from '../core/utils';
import Button from './Button';
import IMGS_SRC from './img';
import storage from './storage';
import HelpTooltipDisplay from './HelpTooltip';



const WIN_DIM = Dimensions.get('window');
const SCREEN_WIDTH = Math.floor(WIN_DIM.width);
const SCREEN_HEIGHT = Math.floor(WIN_DIM.height);

// --- CONSTANTS

const PADDING = 5;
const CARD_BORDER_SIZE = 1;
const HAND_BORDER_SIZE = 2;
const ORIGINAL_CARD_WIDTH = 200;
const ORIGINAL_CARD_HEIGHT = 318;
const CARD_SIZE_RATIO = ORIGINAL_CARD_HEIGHT / ORIGINAL_CARD_WIDTH;
const SHOWN_CARD_WIDTH_PCT = 0.6;
const SMALL_CARD_SIZE_PCT = 0.8;

const ASSET_ICON_BORDER_SIZE = 1;

const HEADER_MENU_HEIGHT = 60;

// --- PLAYER NAME

const PLAYER_NAME_HEIGHT = 30;

// --- CARDS

const CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN = (SCREEN_WIDTH - 2 * PADDING + 7 * CARD_BORDER_SIZE - 6 * HAND_BORDER_SIZE) / ((7 * SHOWN_CARD_WIDTH_PCT + 1) * ORIGINAL_CARD_WIDTH);
const CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN = (SCREEN_HEIGHT - (Constants.statusBarHeight || 0) - 2 * PADDING - HEADER_MENU_HEIGHT - (2 * PLAYER_NAME_HEIGHT + 6 * PADDING + 6 * HAND_BORDER_SIZE + (2 * (7 * SHOWN_CARD_WIDTH_PCT + 1) - 3) * CARD_BORDER_SIZE)) / (ORIGINAL_CARD_WIDTH * (CARD_SIZE_RATIO * (SMALL_CARD_SIZE_PCT + 1) + SMALL_CARD_SIZE_PCT * (7 * SHOWN_CARD_WIDTH_PCT + 1)));
const CARD_SIZE_PCT = Math.min(
    1,
    CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN,
    CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN,
);

const CARD_WIDTH = Math.floor(CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH);
const CARD_HEIGHT = Math.floor(CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT);
const FULL_CARD_WIDTH = CARD_WIDTH + CARD_BORDER_SIZE * 2;
const FULL_CARD_HEIGHT = CARD_HEIGHT + CARD_BORDER_SIZE * 2;

const SMALL_CARD_WIDTH = Math.floor(CARD_WIDTH * SMALL_CARD_SIZE_PCT);
const SMALL_CARD_HEIGHT = Math.floor(CARD_HEIGHT * SMALL_CARD_SIZE_PCT);
const FULL_SMALL_CARD_WIDTH = SMALL_CARD_WIDTH + CARD_BORDER_SIZE * 2;
const FULL_SMALL_CARD_HEIGHT = SMALL_CARD_HEIGHT + CARD_BORDER_SIZE * 2;

// --- HAND

const PLAYER_HAND_WIDTH = (Math.floor(FULL_CARD_WIDTH * SHOWN_CARD_WIDTH_PCT) - CARD_BORDER_SIZE) * 7 + FULL_CARD_WIDTH;
const PLAYER_HAND_HEIGHT = FULL_CARD_HEIGHT;
const FULL_PLAYER_HAND_WIDTH = PLAYER_HAND_WIDTH + 2 * PADDING + 2 * HAND_BORDER_SIZE;
const FULL_PLAYER_HAND_HEIGHT = PLAYER_HAND_HEIGHT + 2 * PADDING + 2 * HAND_BORDER_SIZE;

const OTHER_HAND_WIDTH = (Math.floor(FULL_SMALL_CARD_WIDTH * SHOWN_CARD_WIDTH_PCT) - CARD_BORDER_SIZE) * 7 + FULL_SMALL_CARD_WIDTH;
const OTHER_HAND_HEIGHT = FULL_SMALL_CARD_HEIGHT;
const FULL_OTHER_HAND_WIDTH = OTHER_HAND_WIDTH + 2 * PADDING + 2 * HAND_BORDER_SIZE;
const FULL_OTHER_HAND_HEIGHT = OTHER_HAND_HEIGHT + 2 * PADDING + 2 * HAND_BORDER_SIZE;

const PLAYER_NAME_WIDTH = FULL_OTHER_HAND_WIDTH;

// --- TABLE CENTER

const TABLE_CENTER_SIZE = FULL_SMALL_CARD_WIDTH + 2 * PADDING;
const ASSET_ICON_SIZE = Math.max(28, Math.min(100, TABLE_CENTER_SIZE - 42 - 2 * PADDING));
const TABLE_SIZE = TABLE_CENTER_SIZE + 2 * FULL_SMALL_CARD_HEIGHT + 2 * PADDING;

const ACTION_BTN_WIDTH = FULL_SMALL_CARD_HEIGHT * 2;
const ACTION_BTN_HEIGHT = Math.min(32, Math.floor((FULL_SMALL_CARD_HEIGHT - PADDING) / 2));

// --- SCORE FONT SIZE

const SMALL_FONT_SIZE = Math.max(
    11,
    Math.round(11 + 4 * CARD_SIZE_PCT),
)

const HEADER_MENU_WIDTH = Math.min(SCREEN_WIDTH, (FULL_OTHER_HAND_HEIGHT + PLAYER_NAME_HEIGHT) * 2 + TABLE_SIZE);



const seat_player_idx = (myIdx, seat) => (myIdx + seat) % 4;

const prettyWinType = (win_type) => {
    switch (win_type) {
        case utils.WIN_TYPE_CAPO:
            return ": Capot!";
        case utils.WIN_TYPE_DKHOL:
            return ": Dedans!";
        default:
            return "";
    }
}


class Card extends React.Component {
    animV = new Animated.Value(0)

    state = {
        animV: this.animV,
        raiseAnimMine: Animated.multiply(this.animV, -CARD_HEIGHT),
        raiseAnimOther: Animated.multiply(this.animV, -SMALL_CARD_HEIGHT),
    };

    componentDidMount() {
        if (this.props.tierceRaise) { this.animV.setValue(0.33); return this.animateFullRaise(); }
        if (this.props.raise) return this.animateSemiRaise();
    }

    componentDidUpdate(prevProps) {
        if (this.props.tierceRaise && !prevProps.tierceRaise) return this.animateFullRaise();
        if (!this.props.tierceRaise && prevProps.tierceRaise) return this.animateFold();
        if (this.props.raise && !prevProps.raise) return this.animateSemiRaise();
        if (!this.props.raise && prevProps.raise) return this.animateFold();
    }

    componentWillUnmount() {
        if (this.animRaise) this.animRaise.stop();
        if (this.animFold) this.animFold.stop();
    }

    animateSemiRaise = () => {
        this.animRaise = Animated.timing(this.state.animV, {
            toValue: 0.33,
            duration: 150,
        });
        this.animRaise.start();
    };

    animateFullRaise = () => {
        this.animRaise = Animated.timing(this.state.animV, {
            toValue: 1,
            duration: 350,
        });
        this.animRaise.start();
    };

    animateFold = () => {
        this.animFold = Animated.timing(this.state.animV, {
            toValue: 0,
            duration: 150,
        });
        this.animFold.start();
    };

    onPress = () => {
        if (!this.props.onPress) return;
        return this.props.onPress(this.props.card);
    }

    render() {
        const { idx, card, isCropped, seat, active, inHand, zombie, error, settings } = this.props;
        const isMine = seat === 0;
        const isSmallCard = !(inHand && isMine);
        const { raiseAnimMine, raiseAnimOther } = this.state;

        const name = `CARD_${settings.cards}_${card || "cover"}_IMG`;

        let transforms = [];
        if (!inHand) {
            if (seat === 1) {
                transforms.push({ rotate: "270deg" });
            } else if (seat === 2) {
                transforms.push({ rotate: "180deg" });
            } else if (seat === 3) {
                transforms.push({ rotate: "90deg" });
            }
        } else {
            transforms.push({ translateY: isMine ? raiseAnimMine : raiseAnimOther });
        }

        let width = CARD_WIDTH, height = CARD_HEIGHT;
        if (isSmallCard) {
            width = SMALL_CARD_WIDTH; height = SMALL_CARD_HEIGHT;
        }
        if (isCropped) {
            width = Math.floor(width * SHOWN_CARD_WIDTH_PCT);
        }

        const img = (
            <View style={{
                width: width,
                height: height,
            }}>
                <Animated.Image
                    style={{
                        zIndex: 300 + (idx || 0),
                        margin: 0,
                        padding: 0,
                        width: (isSmallCard) ? SMALL_CARD_WIDTH : CARD_WIDTH,
                        height: (isSmallCard) ? SMALL_CARD_HEIGHT : CARD_HEIGHT,
                        borderWidth: CARD_BORDER_SIZE,
                        borderColor: (error) ? "red" : "grey",
                        opacity: (zombie) ? 0.8 : null,
                        transform: transforms,
                    }}
                    source={IMGS_SRC[name]} />
            </View>

        );

        if (!active) {
            return img;
        }

        return (
            <TouchableHighlight onPress={this.onPress} underlayColor="transparent" activeOpacity={1}>
                {img}
            </TouchableHighlight>
        );
    }
}

class Hand extends React.Component {

    state = {}

    componentDidUpdate(prevProps) {
        const tierces = this.props.tierces;
        if (_.isEqual(prevProps.tierces, tierces) || _.isEmpty(tierces)) return;

        this.setState({
            showTierce: tierces[tierces.length - 1],
        }, this.stopShowingTierce);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);
    }

    stopShowingTierce = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({
                showTierce: null,
            })
        }, 5000);
    }

    render() {
        const { playerName, cards, seat, onPress, isActive, isDeclaring, tierce, lastPlayed, cardError, isBuyer, isPhasePlay, settings } = this.props;
        const { showTierce } = this.state;
        let isMine = seat === 0;
        let _cards = _.cloneDeep(cards);
        if ((isDeclaring || showTierce) && lastPlayed.length && isMine) _cards.push(lastPlayed[0]);

        let isCardInShownTierce = {};
        if (showTierce) {
            if (!isMine) {
                let offset = 1;
                if (_cards.length < showTierce.length) _cards.push(0);
                for (let i = 0; i < showTierce.length; i++) {
                    _cards[(offset + i) % _cards.length] = showTierce[i];
                }
            }
            for (let i = 0; i < showTierce.length; i++) {
                isCardInShownTierce[showTierce[i]] = true;
            }
        }

        let transforms = [];
        if (seat === 1) {
            transforms.push({ rotate: "270deg" });
        } else if (seat === 2) {
            transforms.push({ rotate: "180deg" });
        } else if (seat === 3) {
            transforms.push({ rotate: "90deg" });
        }

        const handSize = {
            width: (!isMine) ? FULL_OTHER_HAND_WIDTH : FULL_PLAYER_HAND_WIDTH,
            height: (!isMine) ? FULL_OTHER_HAND_HEIGHT : FULL_PLAYER_HAND_HEIGHT,
        };

        let containerSize = {
            width: handSize.width,
            height: handSize.height + PLAYER_NAME_HEIGHT,
        };
        if (seat % 2 === 1) {
            containerSize = {
                width: containerSize.height,
                height: containerSize.width,
            };
        }

        const playerNameComponent = (
            <View style={{
                width: PLAYER_NAME_WIDTH,
                height: PLAYER_NAME_HEIGHT,
                padding: PADDING,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
            }} >
                {isBuyer ? <View style={{ backgroundColor: "white", width: PADDING, height: PADDING, marginRight: 5, transform: [{ rotate: "45deg" }] }} /> : null}
                <Text style={{ color: ((isActive) ? "white" : "rgb(168, 155, 120)"), transform: (seat === 2) ? [{ scaleY: -1 }, { scaleX: -1 }] : [] }}>
                    {playerName}
                </Text>
            </View>
        );

        return (
            <View style={{
                alignItems: "center",
                justifyContent: "center",
                ...containerSize,
            }} >
                <View style={{
                    alignItems: "center",
                    justifyContent: "center",
                    transform: transforms,
                }}>
                    {playerNameComponent}
                    <View style={{
                        alignItems: "center",
                        justifyContent: "center",
                        borderStyle: "solid",
                        borderColor: (isActive) ? "white" : "rgb(168, 155, 120)",
                        backgroundColor: isActive ? "rgb(168, 155, 120)" : null,
                        borderWidth: HAND_BORDER_SIZE,
                        width: (seat !== 0) ? FULL_OTHER_HAND_WIDTH : FULL_PLAYER_HAND_WIDTH,
                        height: (seat !== 0) ? FULL_OTHER_HAND_HEIGHT : FULL_PLAYER_HAND_HEIGHT,
                        padding: PADDING,
                    }}>
                        <View style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            {_.map(_.sortBy(_cards), (card, idx) => (
                                <Card key={`${seat}-${(isMine) ? card : idx}`}
                                    error={(isMine) ? cardError[card] : null}
                                    card={card}
                                    settings={settings}
                                    seat={seat}
                                    active={isPhasePlay && isMine && isActive && !showTierce}
                                    inHand
                                    idx={idx}
                                    isCropped={idx < _cards.length - 1}
                                    onPress={onPress}
                                    raise={(showTierce && isCardInShownTierce[card]) || (isDeclaring && tierce[card])}
                                    tierceRaise={isCardInShownTierce[card]}
                                    zombie={lastPlayed[0] === card} />
                            ))}
                        </View>
                    </View>
                </View >
            </View>
        );
    }
}

class Table extends React.Component {

    animV = new Animated.Value(1)
    animBoughtV = new Animated.Value(1)

    state = {
        fadeAnim: this.animV,
        transAnim: Animated.multiply(Animated.add(Animated.multiply(this.animV, -1), 1), CARD_WIDTH),
        revTransAnim: Animated.multiply(Animated.add(Animated.multiply(this.animV, -1), 1), -CARD_WIDTH),
        isAnimating: false,

        animBoughtV: this.animBoughtV,
        animBought: Animated.multiply(Animated.add(Animated.multiply(this.animBoughtV, -1), 1), FULL_SMALL_CARD_HEIGHT),
        revAnimBought: Animated.multiply(Animated.add(Animated.multiply(this.animBoughtV, -1), 1), -FULL_SMALL_CARD_HEIGHT),
    };

    componentDidUpdate(prevProps) {
        const table = this.props.table;
        if (utils.isTableAllFilled(table) && !utils.isTableAllFilled(prevProps.table)) {
            this.setState({
                isAnimating: true
            }, this.fadeOut);
        } else if (!utils.isTableAllFilled(table) && utils.isTableAllFilled(prevProps.table)) {
            this.setState({
                isAnimating: false
            }, () => {
                this.animV.setValue(1);
            });
        }

        if (prevProps.phase === "choose_asset" && this.props.phase === "play") {
            const boughtCard = prevProps.table[0];
            this.setState({
                boughtCard: boughtCard,
            }, () => {
                Animated.timing(this.state.animBoughtV, {
                    toValue: 0,
                    duration: 1000,
                }).start(() => this.setState({
                    boughtCard: null,
                }));
            });
        }
    }

    componentWillUnmount() {
        if (this.anim) this.anim.stop();
    }

    fadeOut = () => {
        this.animV.setValue(1);
        this.anim = Animated.timing(this.state.fadeAnim, {
            toValue: 0,
            delay: 2000,
            duration: 2000,
        });

        this.anim.start(() => {
            this.setState({
                isAnimating: false
            }, () => this.animV.setValue(1));
        });
    };

    onChooseAsset2emeC = () => this.props.onPress("c00");
    onChooseAsset2emeB = () => this.props.onPress("b00");
    onChooseAsset2emeE = () => this.props.onPress("e00");
    onChooseAsset2emeO = () => this.props.onPress("o00");

    render() {
        const { phase, settings, asset, myScore, opScore, buyer, onAssetCardMountedLayout, on2emeLayout, onTableMySlotLayout, onTierceBtnLayout, table, is2eme, myIdx, onPress, gameOver, winner, onPass, showTiersAction, onDeclare, onDeclareCancel, isDeclaring, onContinue, isActive } = this.props;
        const { isAnimating, fadeAnim, transAnim, revTransAnim, boughtCard, animBoughtV, animBought, revAnimBought } = this.state;
        const playerAtSeat = _.map([0, 1, 2, 3], i => seat_player_idx(myIdx, i));

        if (phase === "choose_asset" || boughtCard) {
            const tableAsset = (table[0] || boughtCard).substr(0, 1);
            let animateBoughtTranslate = { translateY: animBought };
            if (buyer === playerAtSeat[1]) {
                animateBoughtTranslate = { translateX: animBought };
            } else if (buyer === playerAtSeat[2]) {
                animateBoughtTranslate = { translateY: revAnimBought };
            } else if (buyer === playerAtSeat[3]) {
                animateBoughtTranslate = { translateX: revAnimBought };
            }
            return (
                <View style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", width: TABLE_SIZE, height: TABLE_SIZE }} >
                    <View onLayout={onAssetCardMountedLayout}>
                        {boughtCard ? <Animated.View
                            style={{
                                opacity: animBoughtV,
                                transform: [animateBoughtTranslate],
                            }}>
                            <Card settings={settings} card={table[0] || boughtCard} />
                        </Animated.View> : <Card settings={settings}
                            card={table[0] || boughtCard}
                            onPress={onPress}
                            active={isActive && !boughtCard && !is2eme} />}

                    </View>
                    {(isActive && !boughtCard) ? (
                        <Button onPress={onPass} title={table[0].substr(1) === "10" ? "Forcé!" : "Passer"}
                            disabled={table[0].substr(1) === "10"}
                            style={{
                                width: Math.floor(FULL_SMALL_CARD_WIDTH * 1.5),
                                height: ACTION_BTN_HEIGHT,
                                marginTop: 2 * PADDING,
                                zIndex: 600,
                                color: "rgb(83, 64, 47)",
                                backgroundColor: "white"
                            }} />
                    ) : <View style={{
                        width: Math.floor(FULL_SMALL_CARD_WIDTH * 1.5),
                        height: ACTION_BTN_HEIGHT,
                        marginTop: 2 * PADDING,
                    }} />}
                    {(is2eme && !boughtCard) ? (
                        <View onLayout={on2emeLayout} style={{ marginTop: 2 * PADDING, height: ASSET_ICON_SIZE + 2 * PADDING, flexDirection: "row" }} >
                            {tableAsset !== "c" ? (
                                <TouchableHighlight style={{ margin: PADDING }} disabled={!isActive} onPress={this.onChooseAsset2emeC}>
                                    <Image
                                        style={{
                                            width: ASSET_ICON_SIZE,
                                            height: ASSET_ICON_SIZE,
                                            borderColor: 'rgb(168, 155, 120)',
                                            borderWidth: ASSET_ICON_BORDER_SIZE,
                                        }}
                                        source={IMGS_SRC[`ASSET_${settings.cards}_c_IMG`]} />
                                </TouchableHighlight>
                            ) : null}
                            {tableAsset !== "b" ? (
                                <TouchableHighlight style={{ margin: PADDING }} disabled={!isActive} onPress={this.onChooseAsset2emeB}>
                                    <Image
                                        style={{
                                            width: ASSET_ICON_SIZE,
                                            height: ASSET_ICON_SIZE,
                                            borderColor: 'rgb(168, 155, 120)',
                                            borderWidth: ASSET_ICON_BORDER_SIZE,
                                        }}
                                        source={IMGS_SRC[`ASSET_${settings.cards}_b_IMG`]} />
                                </TouchableHighlight>
                            ) : null}
                            {tableAsset !== "e" ? (
                                <TouchableHighlight style={{ margin: PADDING }} disabled={!isActive} onPress={this.onChooseAsset2emeE}>
                                    <Image
                                        style={{
                                            width: ASSET_ICON_SIZE,
                                            height: ASSET_ICON_SIZE,
                                            borderColor: 'rgb(168, 155, 120)',
                                            borderWidth: ASSET_ICON_BORDER_SIZE,
                                        }}
                                        source={IMGS_SRC[`ASSET_${settings.cards}_e_IMG`]} />
                                </TouchableHighlight>
                            ) : null}
                            {tableAsset !== "o" ? (
                                <TouchableHighlight style={{ margin: PADDING }} disabled={!isActive} onPress={this.onChooseAsset2emeO}>
                                    <Image
                                        style={{
                                            width: ASSET_ICON_SIZE,
                                            height: ASSET_ICON_SIZE,
                                            borderColor: 'rgb(168, 155, 120)',
                                            borderWidth: ASSET_ICON_BORDER_SIZE,
                                        }}
                                        source={IMGS_SRC[`ASSET_${settings.cards}_o_IMG`]} />
                                </TouchableHighlight>
                            ) : null}
                        </View>
                    ) : <View style={{ marginTop: 2 * PADDING, height: 32 + 2 * PADDING }} />}
                </View>
            )
        }

        const isTableFull = utils.isTableAllFilled(table);
        let animateTranslate = { translateY: transAnim };
        if (winner === playerAtSeat[1]) {
            animateTranslate = { translateX: transAnim };
        } else if (winner === playerAtSeat[2]) {
            animateTranslate = { translateY: revTransAnim };
        } else if (winner === playerAtSeat[3]) {
            animateTranslate = { translateX: revTransAnim };
        }

        return (
            <View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", width: TABLE_SIZE, height: TABLE_SIZE }} >
                <View style={{ alignItems: "center", justifyContent: "center", width: FULL_SMALL_CARD_WIDTH + 2 * PADDING, height: FULL_SMALL_CARD_HEIGHT + PADDING }}>
                    {(table[playerAtSeat[2]]) ? (
                        (isTableFull) ? (
                            ((isAnimating) ? (<Animated.View
                                style={{
                                    opacity: fadeAnim,
                                    transform: [animateTranslate],
                                }} >
                                <Card settings={settings} card={table[playerAtSeat[2]]} seat={2} />
                            </Animated.View>) : null)
                        ) : (
                                <Card settings={settings} card={table[playerAtSeat[2]]} seat={2} />
                            )
                    ) : null}
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "row" }} >
                    <View style={{ alignItems: "center", justifyContent: "center", height: FULL_SMALL_CARD_WIDTH + 2 * PADDING, width: FULL_SMALL_CARD_HEIGHT + PADDING }} >
                        {(table[playerAtSeat[3]]) ? (
                            (isTableFull) ? (
                                ((isAnimating) ? (<Animated.View
                                    style={{
                                        opacity: fadeAnim,
                                        transform: [animateTranslate],
                                    }} >
                                    <Card settings={settings} card={table[playerAtSeat[3]]} seat={3} />
                                </Animated.View>) : null)
                            ) : (
                                    <Card settings={settings} card={table[playerAtSeat[3]]} seat={3} />
                                )
                        ) : null}
                    </View>
                    <View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", padding: PADDING, width: TABLE_CENTER_SIZE, height: TABLE_CENTER_SIZE }}>
                        <View style={{ flexDirection: "row" }} >
                            <AnimateNumber style={{ fontSize: SMALL_FONT_SIZE, marginBottom: PADDING, color: "white" }} value={myScore} />
                            <Text style={{ fontSize: SMALL_FONT_SIZE, color: "white" }}>{" - "}</Text>
                            <AnimateNumber style={{ fontSize: SMALL_FONT_SIZE, color: "white" }} value={opScore} />
                        </View>
                        <Image
                            style={{
                                width: ASSET_ICON_SIZE,
                                height: ASSET_ICON_SIZE,
                                borderColor: 'rgb(168, 155, 120)',
                                borderWidth: ASSET_ICON_BORDER_SIZE,
                            }}
                            source={IMGS_SRC[`ASSET_${settings.cards}_${asset}_IMG`]} />
                    </View>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", height: FULL_SMALL_CARD_WIDTH + 2 * PADDING, width: FULL_SMALL_CARD_HEIGHT + PADDING }} >
                        {(table[playerAtSeat[1]]) ? (
                            (isTableFull) ? (
                                ((isAnimating) ? (<Animated.View
                                    style={{
                                        opacity: fadeAnim,
                                        transform: [animateTranslate],
                                    }} >
                                    <Card settings={settings} card={table[playerAtSeat[1]]} seat={1} />
                                </Animated.View>) : null)
                            ) : (
                                    <Card settings={settings} card={table[playerAtSeat[1]]} seat={1} />
                                )
                        ) : null}
                    </View>
                </View>
                <View onLayout={onTableMySlotLayout} style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", width: TABLE_SIZE, height: FULL_SMALL_CARD_HEIGHT + PADDING }}>
                    {(table[playerAtSeat[0]] && ((isTableFull) ? isAnimating : true)) ? (
                        (isTableFull) ? (
                            ((isAnimating) ? (<Animated.View
                                style={{
                                    opacity: fadeAnim,
                                    transform: [animateTranslate],
                                }} >
                                <Card settings={settings} card={table[playerAtSeat[0]]} seat={0} />
                            </Animated.View>) : null)
                        ) : (
                                <Card settings={settings} card={table[playerAtSeat[0]]} seat={0} />
                            )
                    ) : (gameOver) ? (
                        <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "column", position: "absolute" }} >
                            <View style={{ width: FULL_SMALL_CARD_HEIGHT * 2, borderColor: "rgb(168, 155, 120)", borderWidth: 1, justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(83, 64, 47)", padding: 5 }}>
                                {(
                                    (gameOver.winner === null || gameOver.winner === undefined) ? (
                                        <Text style={{ color: "white", fontWeight: "bold" }}>Match nul</Text>
                                    ) : (
                                            (gameOver.winner === (myIdx + 1) % 2) ?
                                                <Text style={{ color: "#e24c2c", fontWeight: "bold" }}>Perdu{prettyWinType(gameOver.win_type)}</Text> :
                                                <Text style={{ color: "#a6c64c", fontWeight: "bold" }}>Gagné{prettyWinType(gameOver.win_type)}</Text>
                                        )
                                )}
                            </View>

                            {(isActive) ? (
                                <Button onPress={onContinue} title="Rejouer" style={{
                                    width: FULL_SMALL_CARD_HEIGHT * 2,
                                    height: ACTION_BTN_HEIGHT,
                                    backgroundColor: "#0B6E4F",
                                    color: "white"
                                }} />
                            ) : null}
                        </View>
                    ) : ((showTiersAction) ? (
                        <View onLayout={onTierceBtnLayout} style={{ justifyContent: "center", alignItems: "center", flexDirection: "column", position: "absolute", width: FULL_SMALL_CARD_HEIGHT * 2, borderColor: "grey", borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: "rgb(83, 64, 47)" }} >
                            <Button
                                style={{
                                    width: ACTION_BTN_WIDTH,
                                    height: ACTION_BTN_HEIGHT,
                                    backgroundColor: "#0B6E4F",
                                    color: "white",
                                }}
                                onPress={onDeclare}
                                title={(isDeclaring) ? "Valider" : "Annonce"} />
                            {(isDeclaring) ? <Button
                                style={{
                                    marginTop: PADDING,
                                    width: ACTION_BTN_WIDTH,
                                    height: ACTION_BTN_HEIGHT,
                                    backgroundColor: "#A71D31",
                                    color: "white",
                                }}
                                onPress={onDeclareCancel}
                                title="Annuler"
                            /> : null}
                        </View>
                    ) : null)}
                </View>
            </View >
        );
    };
}

class Game extends React.Component {

    state = {
        tierce: {},
        cardError: {},
        tooltipQueue: [],
        tooltip: {},
        pushedWalkthrough: {},
        GCtx: { G: null, ctx: null },
        GCtxUpdatesQueue: [],
    };

    componentDidMount() {
        if (!this.props.isLocal) return;
        this.setState({
            GCtx: { G: this.props.G, ctx: this.props.ctx },
            GCtxUpdatesQueue: [],
        });
    }

    componentWillUnmount() {
        if (!this.props.isLocal) return;
        if (this.GCtxUpdateTimeout) clearTimeout(this.GCtxUpdateTimeout);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLocal) return;

        if ((!prevProps.G && this.props.G) || (!prevProps.ctx && this.props.ctx)) {
            return this.setState({
                GCtx: { G: this.props.G, ctx: this.props.ctx },
            });
        }
        const equalGs = _.isEqual(prevProps.G, this.props.G);
        const equalCtxs = _.isEqual(prevProps.ctx, this.props.ctx);
        if (!equalGs || !equalCtxs) {
            let delay = 0;
            let transition = "";
            if (prevProps.ctx && this.props.ctx &&
                prevProps.ctx.phase && this.props.ctx.phase &&
                prevProps.G && this.props.G &&
                prevProps.G.table && this.props.G.table &&
                prevProps.G.tierces && this.props.G.tierces &&
                prevProps.G.played && this.props.G.played &&
                prevProps.ctx.currentPlayer && this.props.ctx.currentPlayer &&
                prevProps.ctx.currentPlayer !== '0') {
                if (prevProps.ctx.phase === "continue" && this.props.ctx.phase === "choose_asset") {
                    delay = 6000;
                    transition = "continue -> choose_asset";
                } else if (prevProps.ctx.phase === "choose_asset" && this.props.ctx.phase === "choose_asset" &&
                    prevProps.ctx.currentPlayer !== this.props.ctx.currentPlayer) {
                    delay = 1000;
                    transition = "choose_asset -> pass";
                } else if (prevProps.ctx.phase === "choose_asset" && this.props.ctx.phase === "play") {
                    delay = 1000;
                    transition = "choose_asset -> play";
                } else if (prevProps.ctx.phase === "play" && this.props.ctx.phase === "play") {
                    const playerIdx = utils.playerIDtoIdx(prevProps.G, prevProps.ctx.currentPlayer);
                    const currentCardsOnTable = _.filter(this.props.G.table, c => Boolean(c)).length;

                    const lastPlayedCards = prevProps.G.played[playerIdx].length;
                    const currPlayedCards = this.props.G.played[playerIdx].length;

                    const lastDeclaredTierces = prevProps.G.tierces[playerIdx].length;
                    const currDeclaredTierces = this.props.G.tierces[playerIdx].length;

                    if (currPlayedCards === lastPlayedCards + 1) {
                        if (utils.isTableAllEmpty(prevProps.G.table) && currPlayedCards === 1) {
                            delay = 5000;
                            transition = "play -> first_played_card_of_game";
                        } else if (utils.canDeclareTierce(this.props.G) || utils.canDeclareTierce(prevProps.G)) {
                            if (currDeclaredTierces) {
                                delay = 6000;
                                transition = "play -> first_card_after_tierce";
                            } else {
                                if (currentCardsOnTable === 1 && lastPlayedCards > 0) {
                                    delay = 5000;
                                    transition = "play -> first_card_of_pli";
                                } else {
                                    delay = 1000;
                                    transition = "play -> any_card";
                                }
                            }
                        } else {
                            if (currentCardsOnTable === 1 && lastPlayedCards > 0) {
                                delay = 5000;
                                transition = "play -> first_card_of_pli";
                            } else if (currentCardsOnTable === 1 && lastPlayedCards === 0) {
                                delay = 3000;
                                transition = "play -> first_card_of_party";
                            } else {
                                delay = 1000;
                                transition = "play -> any_card";
                            }
                        }
                    } else if (currPlayedCards === lastPlayedCards && lastDeclaredTierces + 1 === currDeclaredTierces) {
                        if (!lastDeclaredTierces) {
                            if (currentCardsOnTable === 4) {
                                delay = 5000;
                                transition = "play -> declare_first_tierce_after_pli";
                            } else {
                                delay = 1000;
                                transition = "play -> declare_first_tierce";
                            }
                        } else {
                            delay = 6000;
                            transition = "play -> declare_next_tierce";
                        }
                    }
                }
            }
            this._pushGCtxUpdate({ G: this.props.G, ctx: this.props.ctx }, delay, transition);
        }
    }

    _pushGCtxUpdate = (update, delay, transition) => {
        if (!this.props.isLocal) return;

        this.setState({
            GCtxUpdatesQueue: [...this.state.GCtxUpdatesQueue, { update, delay, transition }]
        }, this._wakeGCtxUpdateWatcher);
    }

    _wakeGCtxUpdateWatcher = () => {
        if (!this.props.isLocal) return;

        if (this.state.GCtxUpdatesOnGoing) return;
        if (!this.state.GCtxUpdatesQueue.length) return;
        let GCtxUpdatesQueue = this.state.GCtxUpdatesQueue;
        let { update, delay, transition } = GCtxUpdatesQueue[0];
        if (!delay) {
            let toPop = 1;
            for (let i = 1; i < GCtxUpdatesQueue.length && !GCtxUpdatesQueue[i].delay; i++) {
                update = GCtxUpdatesQueue[i].update;
                toPop++;
            }
            return this.setState({
                GCtx: update,
                GCtxUpdatesOnGoing: false,
                GCtxUpdatesQueue: GCtxUpdatesQueue.slice(toPop)
            }, this._wakeGCtxUpdateWatcher);
        }

        this.setState({
            GCtxUpdatesOnGoing: true,
            GCtxUpdatesQueue: GCtxUpdatesQueue.slice(1)
        }, () => {
            this.GCtxUpdateTimeout = setTimeout(
                () => this._applyGCtxUpdate(update, this._wakeGCtxUpdateWatcher),
                delay);
        });
    }

    _applyGCtxUpdate = (update, cb) => {
        if (!this.props.isLocal) return;

        this.setState({
            GCtx: update,
            GCtxUpdatesOnGoing: false,
        }, cb);
    }

    onGameLayout = (n) => {
        this.setState({
            gameLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughAssetTooltipIfNot)
    }

    onLayoutHeaderMenu = (n) => {
        this.setState({
            layoutHeaderMenu: n.nativeEvent.layout,
        }, this.pushWalkThroughAssetTooltipIfNot)
    }

    onTableLayout = (n) => {
        this.setState({
            tableLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughAssetTooltipIfNot)
    }

    onTableContainerLayout = (n) => {
        this.setState({
            tableContainerLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughAssetTooltipIfNot)
    }


    onAssetCardMountedLayout = (n) => {
        this.setState({
            assetCardLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughAssetTooltipIfNot)
    }

    on2emeLayout = (n) => {
        this.setState({
            asset2emeLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughAsset2emeTooltipIfNot);
    }

    onTableMySlotLayout = (n) => {
        this.setState({
            tableMySlotLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughTierceIfNot)
    }

    onTierceBtnLayout = (n) => {
        this.setState({
            tierceBtnLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughTierceIfNot)
    }

    onMyHandLayout = (n) => {
        this.setState({
            myHandLayout: n.nativeEvent.layout,
        }, this.pushWalkThroughTierceIfNot)
    }

    pushTooltip = (id, newTooltip, cb) => {
        const { tooltipQueue, tooltip, pushedWalkthrough } = this.state;
        const newPushedWalkthrough = (id) ? { ...pushedWalkthrough, [id]: true } : pushedWalkthrough;
        if (!tooltip.show) {
            this.setState({
                tooltip: { ...newTooltip, id: id },
                pushedWalkthrough: newPushedWalkthrough,
            }, cb);
        } else {
            this.setState({
                tooltipQueue: [{ ...newTooltip, id: id }, ...tooltipQueue],
                pushedWalkthrough: newPushedWalkthrough,
            }, cb);
        }
    }

    onHideTooltip = () => {
        const { tooltipQueue, tooltip } = this.state;
        if (tooltip.id) storage.setItem(tooltip.id, "true", () => { });
        if (tooltipQueue.length === 0) {
            this.setState({
                tooltip: { ...tooltip, show: false },
            });
        } else {
            this.setState({
                tooltip: tooltipQueue[0],
                tooltipQueue: tooltipQueue.slice(1),
            });
        }
    }

    pushWalkThroughAssetTooltipIfNot = () => {
        const { gameLayout, layoutHeaderMenu, assetCardLayout, tableLayout, tableContainerLayout, pushedWalkthrough } = this.state;
        const walkThroughAsset = "walkthrough-asset-card";
        if (!pushedWalkthrough[walkThroughAsset] && gameLayout && layoutHeaderMenu && assetCardLayout && tableLayout && tableContainerLayout) {
            storage.getItem(walkThroughAsset, (err, seen) => {
                if (seen) return;
                this.pushTooltip(walkThroughAsset, {
                    show: true,
                    text: "Appuyiez sur la carte pour choisir sa couleur comme atout.\nSinon, appuyiez sur Passer",
                    position: "top",
                    attachedComponentLayout: {
                        width: assetCardLayout.width,
                        height: assetCardLayout.height,
                        x: assetCardLayout.x + tableLayout.x + tableContainerLayout.x + gameLayout.x,
                        y: assetCardLayout.y + tableLayout.y + tableContainerLayout.y + gameLayout.y,
                    },
                });
            });
            return;
        }
    }

    pushWalkThroughAsset2emeTooltipIfNot = () => {
        const { gameLayout, layoutHeaderMenu, asset2emeLayout, tableLayout, tableContainerLayout, pushedWalkthrough } = this.state;
        const walkThroughAsset2eme = "walkthrough-asset-2eme";
        if (!pushedWalkthrough[walkThroughAsset2eme] && gameLayout && layoutHeaderMenu && asset2emeLayout && tableLayout && tableContainerLayout) {
            storage.getItem(walkThroughAsset2eme, (err, seen) => {
                if (seen) return;
                this.pushTooltip(walkThroughAsset2eme, {
                    show: true,
                    text: "Appuyiez sur une autre couleur pour la choisir comme atout.\nSinon, appuyiez sur Passer",
                    position: "bottom",
                    attachedComponentLayout: {
                        width: asset2emeLayout.width,
                        height: asset2emeLayout.height,
                        x: asset2emeLayout.x + tableLayout.x + tableContainerLayout.x + gameLayout.x,
                        y: asset2emeLayout.y + tableLayout.y + tableContainerLayout.y + gameLayout.y,
                    },
                });
            });
            return;
        }
    }

    pushWalkThroughTierceIfNot = () => {
        const { gameLayout, layoutHeaderMenu, tableMySlotLayout, tableLayout, tierceBtnLayout, tableContainerLayout, pushedWalkthrough } = this.state;
        const walkThroughTierce = "walkthrough-tierce";
        if (!pushedWalkthrough[walkThroughTierce] && gameLayout && layoutHeaderMenu && tableMySlotLayout && tierceBtnLayout && tableLayout && tableContainerLayout) {
            storage.getItem(walkThroughTierce, (err, seen) => {
                if (seen) return;
                this.pushTooltip(walkThroughTierce, {
                    show: true,
                    text: "Appuyiez ici si vous souhaitez déclarer un tierce",
                    position: "bottom",
                    attachedComponentLayout: {
                        width: tierceBtnLayout.width,
                        height: tierceBtnLayout.height,
                        x: tableMySlotLayout.x + tierceBtnLayout.x + tableLayout.x + tableContainerLayout.x + gameLayout.x,
                        y: tableMySlotLayout.y + tierceBtnLayout.y + tableLayout.y + tableContainerLayout.y + gameLayout.y,
                    },
                });
            });
            return;
        }
    }

    pushWalkThroughTierceSelectCardsIfNot = () => {
        const { gameLayout, layoutHeaderMenu, myHandLayout, tableLayout, tableContainerLayout, pushedWalkthrough, isDeclaring } = this.state;
        const walkThroughTierceSelectCards = "walkthrough-tierce-select-cards";
        if (!pushedWalkthrough[walkThroughTierceSelectCards] && gameLayout && layoutHeaderMenu && myHandLayout && tableLayout && tableContainerLayout && isDeclaring) {
            storage.getItem(walkThroughTierceSelectCards, (err, seen) => {
                if (seen) return;
                this.pushTooltip(walkThroughTierceSelectCards, {
                    show: true,
                    text: "Selectionner les cartes du tierce une par une et appuyiez sur Valider",
                    position: "top",
                    attachedComponentLayout: {
                        width: myHandLayout.width,
                        height: myHandLayout.height - PLAYER_NAME_HEIGHT,
                        x: myHandLayout.x + gameLayout.x,
                        y: myHandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
                    },
                });
            });
            return;
        }
    }

    pushWalkThroughTierceSelectCardsIfNot = () => {
        const { gameLayout, layoutHeaderMenu, myHandLayout, tableLayout, tableContainerLayout, pushedWalkthrough, isDeclaring } = this.state;
        const walkThroughTierceSelectCards = "walkthrough-tierce-select-cards";
        if (!pushedWalkthrough[walkThroughTierceSelectCards] && gameLayout && layoutHeaderMenu && myHandLayout && tableLayout && tableContainerLayout && isDeclaring) {
            storage.getItem(walkThroughTierceSelectCards, (err, seen) => {
                if (seen) return;
                this.pushTooltip(walkThroughTierceSelectCards, {
                    show: true,
                    text: "Selectionner les cartes du tierce une par une et appuyiez sur Valider",
                    position: "top",
                    attachedComponentLayout: {
                        width: myHandLayout.width,
                        height: myHandLayout.height - PLAYER_NAME_HEIGHT,
                        x: myHandLayout.x + gameLayout.x,
                        y: myHandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
                    },
                });
            });
            return;
        }
    }

    pushErrorHandPlayTooltip = (error) => {
        const { gameLayout, layoutHeaderMenu, myHandLayout } = this.state;

        if (gameLayout && layoutHeaderMenu && myHandLayout) {
            this.pushTooltip(null, {
                show: true,
                text: error,
                position: "top",
                error: true,
                attachedComponentLayout: {
                    width: myHandLayout.width,
                    height: myHandLayout.height - PLAYER_NAME_HEIGHT,
                    x: myHandLayout.x + gameLayout.x,
                    y: myHandLayout.y + PLAYER_NAME_HEIGHT + gameLayout.y,
                },
            });
        }
    }

    askedForHelp = () => {
        this.setState({
            tooltip: {
                show: true,
                isRulesModal: true,
            }
        })
    }

    showQuitConfirmationModal = () => {
        this.setState({
            tooltip: {
                show: true,
                text: `Voulez-vous vraiment quitter la partie en cours ?`,
                fullScreen: false,
                isConfirmationModal: true,
                onConfirm: this.props.onLeave,
            }
        })
    };

    onPress = (card) => {
        if (!card) return;

        const { G, ctx, moves, playerID } = this.props;

        const myIdx = utils.playerIDtoIdx(G, playerID || "0");
        const currentIdx = utils.playerIDtoIdx(G, ctx.currentPlayer);
        if (myIdx !== currentIdx) return;

        const { isDeclaring } = this.state;
        let tierce = _.cloneDeep(this.state.tierce || {});

        if (ctx.phase === "choose_asset") {
            moves.choose(card.substr(0, 1));
            return;
        }

        if (isDeclaring) {
            tierce[card] = !Boolean(tierce[card]);
            this.setState({
                tierce: tierce,
            });
            return;
        }

        const validate = utils.isValidPlayCardMove(G, myIdx, card);
        if (!validate.valid) {
            let error = "Vous ne pouvez pas jouer cette carte";
            if (validate.reason === utils.PLAY_INVALID_MOVE_SHOULD_PLAY_FIRST_TYPE) {
                error = "Vous possédez la première couleur jouée";
            } else if (validate.reason === utils.PLAY_INVALID_MOVE_SHOULD_PLAY_HIGHER_ASSET) {
                error = "Vous êtes obligé jouer une carte atout plus forte";
            } else if (validate.reason === utils.PLAY_INVALID_MOVE_SHOULD_FIER) {
                error = "Vous êtes obligé de jouer une carte atout";
            } else if (validate.reason === utils.PLAY_INVALID_MOVE_SHOULD_FIER_WITH_HIGHER) {
                error = "Vous êtes obligé de jouer une carte atout plus forte que votre adversaire";
            }
            return this.setState({
                cardError: {
                    ...this.state.cardError,
                    [card]: error,
                },
            }, () => this.pushErrorHandPlayTooltip(error));
        }

        moves.play(card);
        this.setState({
            cardError: {}
        });
    }

    onPass = () => {
        const { moves } = this.props;
        moves.pass();
    }

    onDeclare = () => {
        if (this.state.isDeclaring) {
            const { moves, G, ctx } = this.props;
            const tierce = _.filter(_.keys(this.state.tierce), k => this.state.tierce[k]);

            if (!utils.isValidTierceDeclare(G, ctx, tierce)) {
                return this.setState({
                    isDeclaring: false,
                    tierce: {},
                }, () => this.pushErrorHandPlayTooltip("Annonce non-valide"));
            }

            moves.declare(tierce);
            this.setState({
                isDeclaring: false,
                tierce: {},
            });
            return;
        }
        this.setState({
            isDeclaring: true,
            tierce: {},
        }, this.pushWalkThroughTierceSelectCardsIfNot);
    }

    onDeclareCancel = () => {
        this.setState({
            isDeclaring: false,
            tierce: {},
        });
    }

    onContinue = () => {
        const { moves } = this.props;
        moves.continue();
    }

    render() {
        const { playerID, gameMetadata, settings, setSettings, isLocal, username } = this.props;
        const { tooltip } = this.state;

        let G = this.props.G;
        let ctx = this.props.ctx;
        if (isLocal) {
            G = this.state.GCtx.G;
            ctx = this.state.GCtx.ctx;
        }

        if (!G) return <Text style={{ color: "white" }}>Chargement...</Text>;

        const myIdx = utils.playerIDtoIdx(G, playerID || "0");
        const currentIdx = utils.playerIDtoIdx(G, ctx.currentPlayer);
        let playersNames = _.map(gameMetadata, (i) => i.name);
        const playerAtSeat = _.map([0, 1, 2, 3], i => seat_player_idx(myIdx, i));
        let playerIdxToSeat = [-1, -1, -1, -1];
        _.each(playerAtSeat, (s) => {
            playerIdxToSeat[playerAtSeat[s]] = s;
        });
        if (isLocal) playersNames[playerAtSeat[0]] = username;
        const tierces = G.tierces;

        return (
            <View style={{ width: SCREEN_WIDTH, height: SCREEN_HEIGHT, justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} >
                <View onLayout={this.onLayoutHeaderMenu} style={{ width: HEADER_MENU_WIDTH, height: HEADER_MENU_HEIGHT, padding: 2 * PADDING, flexDirection: "row" }} >
                    <Button style={{
                        backgroundColor: 'rgb(168, 155, 120)',
                        borderWidth: 1,
                        width: 40,
                        borderColor: 'white',
                    }} onPress={this.showQuitConfirmationModal} icon="ios-log-out" iconSize={22} />
                    <Button style={{
                        marginLeft: 2 * PADDING,
                        backgroundColor: 'rgb(168, 155, 120)',
                        borderWidth: 1,
                        width: 40,
                        borderColor: 'white',
                    }} onPress={this.askedForHelp} icon="ios-help-circle-outline" iconSize={22} />
                    <Button style={{
                        marginLeft: 2 * PADDING,
                        backgroundColor: 'rgb(168, 155, 120)',
                        borderWidth: 1,
                        width: 40,
                        borderColor: 'white',
                    }} onPress={this.props.showSettingsModal} icon="ios-cog" iconSize={22} />
                    <View style={{ flex: 1 }} />
                    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                        <Text style={{ color: "white" }}>{"score total:   "}</Text>
                        <AnimateNumber style={{ color: "white" }} value={G.total_scores[myIdx % 2]} />
                        <Text style={{ color: "white" }}>{" - "}</Text>
                        <AnimateNumber style={{ color: "white" }} value={G.total_scores[(myIdx + 1) % 2]} />
                    </View>
                </View>
                <View onLayout={this.onGameLayout}>
                    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                        <Hand settings={settings} isActive={!ctx.gameover && currentIdx === playerAtSeat[2]} isBuyer={G.buyer === playerAtSeat[2]} playerName={playersNames[playerAtSeat[2]] || `Joueur ${playerAtSeat[2]}`} onPress={this.onPress} cards={G.hands[playerAtSeat[2]]} seat={2} tierces={tierces[playerAtSeat[2]]} lastPlayed={G.played[playerAtSeat[2]]} />
                    </View>
                    <View onLayout={this.onTableContainerLayout} style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <View style={{ justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                            <Hand settings={settings} isActive={!ctx.gameover && currentIdx === playerAtSeat[3]} isBuyer={G.buyer === playerAtSeat[3]} playerName={playersNames[playerAtSeat[3]] || `Joueur ${playerAtSeat[3]}`} onPress={this.onPress} cards={G.hands[playerAtSeat[3]]} seat={3} tierces={tierces[playerAtSeat[3]]} lastPlayed={G.played[playerAtSeat[3]]} />
                        </View>
                        <View onLayout={this.onTableLayout} style={{ justifyContent: "center", alignItems: "center", width: TABLE_SIZE, height: TABLE_SIZE }} >
                            <ImageBackground source={IMGS_SRC['back']}
                                style={{
                                    width: TABLE_SIZE,
                                    height: TABLE_SIZE,
                                }} >
                                <Table asset={G.asset}
                                    settings={settings}
                                    onAssetCardMountedLayout={this.onAssetCardMountedLayout}
                                    buyer={G.buyer}
                                    is2eme={((G.party_turn - 1) % 8) >= 4}
                                    on2emeLayout={this.on2emeLayout}
                                    onTableMySlotLayout={this.onTableMySlotLayout}
                                    onTierceBtnLayout={this.onTierceBtnLayout}
                                    onPress={this.onPress}
                                    onPass={this.onPass}
                                    onContinue={this.onContinue}
                                    isActive={currentIdx === myIdx}
                                    showTiersAction={(currentIdx === myIdx && utils.canDeclareTierce(G))}
                                    isDeclaring={this.state.isDeclaring}
                                    onDeclare={this.onDeclare}
                                    onDeclareCancel={this.onDeclareCancel}
                                    gameOver={G.party_over}
                                    totalScores={G.total_scores}
                                    phase={ctx.phase}
                                    table={G.table}
                                    myIdx={myIdx}
                                    myScore={G.scores[myIdx % 2]}
                                    opScore={G.scores[(myIdx + 1) % 2]}
                                    winner={G.last_winner} />
                            </ImageBackground>
                        </View>
                        <View style={{ justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                            <Hand settings={settings} isActive={!ctx.gameover && currentIdx === playerAtSeat[1]} isBuyer={G.buyer === playerAtSeat[1]} playerName={playersNames[playerAtSeat[1]] || `Joueur ${playerAtSeat[1]}`} onPress={this.onPress} cards={G.hands[playerAtSeat[1]]} seat={1} tierces={tierces[playerAtSeat[1]]} lastPlayed={G.played[playerAtSeat[1]]} />
                        </View>
                    </View>
                    <View onLayout={this.onMyHandLayout} style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", zIndex: 100 }}>
                        <Hand settings={settings} isPhasePlay={ctx.phase === "play"} isActive={!ctx.gameover && currentIdx === playerAtSeat[0]} isBuyer={G.buyer === playerAtSeat[0]} cardError={this.state.cardError} playerName={playersNames[playerAtSeat[0]] || `Joueur ${playerAtSeat[0]}`} onPress={this.onPress} cards={G.hands[playerAtSeat[0]]} seat={0} tierces={tierces[playerAtSeat[0]]} isDeclaring={this.state.isDeclaring} tierce={this.state.tierce} lastPlayed={G.played[playerAtSeat[0]]} />
                    </View>
                </View>
                <HelpTooltipDisplay
                    show={tooltip.show}
                    text={tooltip.text}
                    error={tooltip.error}
                    onHide={this.onHideTooltip}
                    position={tooltip.position}
                    isConfirmationModal={tooltip.isConfirmationModal}
                    onConfirm={tooltip.onConfirm}
                    isRulesModal={tooltip.isRulesModal}
                    settings={settings}
                    setSettings={setSettings}
                    attachedComponentLayout={tooltip.attachedComponentLayout} />
            </View>
        );
    }
}


export default Game; 