import React from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import { Ionicons } from '@expo/vector-icons';


export default function Button({ title, onPress, style, disabled, icon, iconSize }) {
    const textColor = (style || {}).color;
    if (style) delete style.color;
    return (
        <TouchableHighlight
            disabled={disabled}
            style={{ ...styles.button, ...style }}
            onPress={onPress}
            underlayColor='#fff'>
            {(icon && !title) ? (
                <Ionicons name={icon} size={iconSize} color={(textColor || '#fff')} />
            ) : ((title && !icon) ? (
                <Text style={{ ...styles.buttonText, color: (textColor || '#fff') }}>{title}</Text>
            ) : ((title && icon) ? (
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }} >
                    <Ionicons name={icon} size={iconSize} color={(textColor || '#fff')} />
                    <View style={{ flex: 1 }}>
                        <Text style={{ ...styles.buttonText, textAlign: "center", color: (textColor || '#fff') }}>{title}</Text>
                    </View>
                </View>
            ) : null))}
        </TouchableHighlight>
    );
}

const styles = {
    button: {
        padding: 5,
        backgroundColor: 'rgb(168, 155, 120)',
        borderWidth: 1,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: "center"
    },
    buttonText: {
        color: 'white',
    }
};
