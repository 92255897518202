import _ from 'lodash';
import utils from './utils';

function combinationsAV2(n, k) {
    const result = [];
    const combos = [];
    const recurse = start => {
        if (combos.length + (n - start + 1) < k) { return }
        recurse(start + 1);
        combos.push(start);
        if (combos.length === k) {
            result.push(combos.slice());
        } else if (combos.length + (n - start + 2) >= k) {
            recurse(start + 1);
        }
        combos.pop();
    }
    recurse(1, combos);
    return result;
}

function minimax(calculatingPlayerIdx, G, node, maxTurn, depth = 0) {
    let table = _.cloneDeep(node.table);
    table[node.playerIdx] = node.card;

    if (utils.isTableAllFilled(table)) {
        const winner = utils.getTableWinner(G, table);
        const score_table = _.sumBy(table, card => utils.scoreCard(G.asset, card));
        const score = (winner === calculatingPlayerIdx || winner === (calculatingPlayerIdx + 2) % 4) ? score_table : -score_table;
        return score;
    }

    const nextPlayerIdx = (node.playerIdx + 1) % 4;
    const validCards = _.filter(G.hands[nextPlayerIdx], card => utils.isValidPlayCardMove({
        ...G,
        table: table,
    }, nextPlayerIdx, card).valid);
    let childValues = [];
    if (maxTurn) {
        for (let card of validCards) {
            childValues.push(minimax(
                calculatingPlayerIdx,
                G,
                {
                    playerIdx: nextPlayerIdx,
                    table: table,
                    card: card,
                },
                false,
                depth + 1));
        }
        return Math.max(...childValues);
    } else {
        for (let card of validCards) {
            childValues.push(minimax(
                calculatingPlayerIdx,
                G,
                {
                    playerIdx: nextPlayerIdx,
                    table: table,
                    card: card,
                },
                true,
                depth + 1));
        }
        return Math.min(...childValues);
    }
}

let ai = {
    enumerate: (G, ctx) => {
        let moves = [];
        if (ctx.phase === "choose_asset") {
            const table_card = G.table[0];
            const table_type = table_card.substr(0, 1);
            const other_types = _.filter(utils.TYPES, (t) => t !== table_type);
            if (table_card.substr(1) === "10") {
                moves = [
                    { move: "choose", args: [table_type] }
                ];
            } else if ((((G.party_turn - 1) % 8) < 4)) {
                moves = [
                    { move: "choose", args: [table_type] },
                    { move: "pass", args: [] },
                ];
            } else {
                moves = [
                    { move: "choose", args: [other_types[0]] },
                    { move: "choose", args: [other_types[1]] },
                    { move: "choose", args: [other_types[2]] },
                    { move: "pass", args: [] },
                ];
            }
        } else if (ctx.phase === "play") {
            const playerIdx = utils.playerIDtoIdx(G, ctx.currentPlayer);
            if (utils.canDeclareTierce(G)) {
                let hand = _.cloneDeep(G.hands[playerIdx]);
                hand.push(G.played[playerIdx][0]);
                for (let k = 8; k >= 3; k--) {
                    let tierces = _.map(combinationsAV2(8, k), comb => _.map(comb, i => hand[i - 1]));
                    tierces = _.filter(tierces, tierce => utils.isValidTierceDeclare(G, ctx, tierce));
                    if (tierces.length) {
                        moves.push({ move: "declare", args: [tierces[0]] });
                        break;
                    }
                }
            }
            if (!moves.length) {
                let validCards = _.filter(G.hands[playerIdx], (card) => utils.isValidPlayCardMove(G, playerIdx, card).valid);
                let maxCard = null, maxValue = -10000;
                let table = utils.isTableAllFilled(G.table) ? [0, 0, 0, 0] : G.table;
                const isTableAllEmpty = utils.isTableAllEmpty(table);
                for (let card of validCards) {
                    let value = minimax(playerIdx, {
                        ...G,
                        first_played_type: (isTableAllEmpty) ? card.substr(0, 1) : G.first_played_type
                    }, {
                        playerIdx: playerIdx,
                        card: card,
                        table: table,
                    }, true);
                    if (value > maxValue) {
                        maxCard = card;
                        maxValue = value;
                    }
                }
                if (maxCard) {
                    moves.push({ move: "play", args: [maxCard] });
                }
                if (!moves.length) {
                    for (let card of validCards) {
                        moves.push({ move: "play", args: [card] });
                    }
                }
            }
        } else if (ctx.phase === "continue") {
            moves.push({ move: "continue", args: [] });
        }
        return moves;
    },
};

export default ai;