import React from "react";
import { View, Text } from "react-native";


const Privacy = () => (
    <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
        <View style={{ padding: 40 }} >
            <Text style={{ fontSize: 24, fontWeight: "bold" }} >
                Privacy Policy
            </Text>
            <Text style={{ marginTop: 5, fontSize: 16 }} >
                This Privacy Policy is effective as of 04/21/2020.
            </Text>
            <Text style={{ fontSize: 16 }} >
                I do not collect nor store any of your personnal data. The app/website "belote marocain" is a simple game where you can play anonymously.
            </Text>
            <Text style={{ marginTop: 10, fontSize: 18, fontWeight: "bold" }} >
                Cookies and Local storage
            </Text>
            <Text style={{ marginTop: 5, fontSize: 16 }} >
                The app/website uses cookies to store your most recent player credentials to resume any online parties you might have joined before closing the app/website.
            </Text>
            <Text style={{ marginTop: 5, fontSize: 18, fontWeight: "bold" }} >
                Advertisement
                </Text>
            <Text style={{ marginTop: 5, fontSize: 16 }} >
                There are no advertisements on the app/website
            </Text>
            <Text style={{ marginTop: 5, fontSize: 18, fontWeight: "bold" }} >
                Contact Information
            </Text>
            <Text style={{ marginTop: 5, fontSize: 16 }} >
                For any questions or concerns regarding the privacy policy, please send me an email to sbai.ayoub@hotmail.com
            </Text>
        </View>
    </View>
);


export default Privacy;