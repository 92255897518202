import _ from 'lodash';
import { INVALID_MOVE } from 'boardgame.io/core';
import utils from './utils';
import ai from './ai';

const newGame = () => {
    let deck = _.shuffle(utils.DECK);
    let hands = [];
    for (let i = 0; i < 4; i++) {
        hands.push(deck.slice(i * 5, (i + 1) * 5));
    }

    return {
        deck: deck.slice(21),
        asset: null,
        table: [deck[20]],
        hands: hands,
        scores: [0, 0],

        buyer: null,

        last_winner: null,
        first_played_type: null,

        played: [[], [], [], []],
        tierces: [[], [], [], []],
        party_over: null,
        party_turn: 1,
    }
}

const Belote = {
    name: "belote",

    phases: {
        choose_asset: {
            start: true,
            moves: {
                choose: (G, ctx, asset) => {
                    if (!_.includes(utils.TYPES, asset)) {
                        return INVALID_MOVE;
                    }
                    let table_card = G.table[0];
                    if (table_card.substr(1) === "10" && asset !== table_card.substr(0, 1)) {
                        return INVALID_MOVE;
                    }
                    if ((((G.party_turn - 1) % 8) < 4) && asset !== table_card.substr(0, 1)) {
                        return INVALID_MOVE;
                    }
                    if ((((G.party_turn - 1) % 8) >= 4) && asset === table_card.substr(0, 1)) {
                        return INVALID_MOVE;
                    }
                    return { ...G, asset: asset, buyer: G.playerIdx[ctx.currentPlayer] };
                },
                pass: (G, ctx) => {
                    let table_card = G.table[0];
                    if (table_card.substr(1) === "10") {
                        return INVALID_MOVE;
                    }
                    if (G.party_turn % 8 === 0) {
                        return { ...G, ...(newGame()), first_player: ((G.first_player + 1) % 4), party_turn: G.party_turn + 1 };
                    }
                    return { ...G, party_turn: G.party_turn + 1 };
                }
            },
            turn: {
                order: {
                    first: (G, ctx) => G.first_player,
                    next: (G, ctx) => (G.party_turn % 8 === 1 && G.party_turn > 1) ? G.first_player : ((ctx.playOrderPos + 1) % 4),
                },
                endIf: (G, ctx) => true,
            },
            endIf: (G, ctx) => {
                if (G.asset !== null) return { end: true };
            },
            onEnd: (G, ctx) => {
                let hands = _.cloneDeep(G.hands);
                hands[G.buyer].push(G.table[0]);
                hands[G.buyer].push(G.deck[0]);
                hands[G.buyer].push(G.deck[1]);

                for (let i = 1; i < 4; i++) {
                    hands[(G.buyer + i) % 4].push(G.deck[2 + (i - 1) * 3]);
                    hands[(G.buyer + i) % 4].push(G.deck[3 + (i - 1) * 3]);
                    hands[(G.buyer + i) % 4].push(G.deck[4 + (i - 1) * 3]);
                }

                return { ...G, hands: hands, table: [0, 0, 0, 0], deck: [] };
            },
            next: 'play',
        },
        play: {
            moves: {
                play: (G, ctx, card) => {
                    let playerIdx = G.playerIdx[ctx.currentPlayer];
                    if (!utils.isValidPlayCardMove(G, playerIdx, card).valid) return INVALID_MOVE;

                    let hands = _.cloneDeep(G.hands);
                    let played = _.cloneDeep(G.played);
                    let table = _.cloneDeep(G.table);
                    let hand = hands[playerIdx];

                    const is_table_all_empty = utils.isTableAllEmpty(table);
                    const is_table_all_filled = utils.isTableAllFilled(table);

                    if (is_table_all_empty || is_table_all_filled) {
                        table = [0, 0, 0, 0];
                    }
                    hand = _.filter(hand, (c) => c !== card);
                    hands[playerIdx] = hand;
                    played[playerIdx].push(card);
                    table[playerIdx] = card;

                    let first_played_type = G.first_played_type;
                    if ((_.filter(table, (c) => c === 0)).length === 3) {
                        first_played_type = card.substr(0, 1);
                    }

                    let winner = null;
                    let scores = _.cloneDeep(G.scores);
                    if (!_.includes(table, 0)) {
                        winner = utils.getTableWinner(G, table);
                        let score = 0;
                        for (let card of table) {
                            score += utils.scoreCard(G.asset, card);
                        }
                        scores[winner % 2] += score;
                    }

                    ctx.events.endTurn();

                    return { ...G, hands: hands, table: table, first_played_type: first_played_type, last_winner: winner, scores: scores, played: played };
                },
                declare: (G, ctx, tierce) => {
                    let playerIdx = G.playerIdx[ctx.currentPlayer];
                    if (!utils.isValidTierceDeclare(G, ctx, tierce)) {
                        return INVALID_MOVE;
                    }
                    let tierces = _.cloneDeep(G.tierces);
                    tierces[playerIdx].push(_.sortBy(tierce, utils.tierceSortOrder));

                    return { ...G, tierces: tierces };
                },

            },
            turn: {
                order: {
                    first: (G, ctx) => G.first_player,
                    next: (G, ctx) => (G.last_winner !== null) ? G.last_winner : ((ctx.playOrderPos + 1) % 4),
                },
                endIf: (G, ctx) => false,
            },
            endIf: (G, ctx) => {
                for (let hand of G.hands) {
                    if (hand.length > 0) return;
                }
                return { end: true };
            },
            onEnd: (G, ctx) => {
                let scores = _.cloneDeep(G.scores), total_scores = _.cloneDeep(G.total_scores);
                scores[G.last_winner % 2] += 10;
                for (let i = 0; i < 2; i++) {
                    if (scores[i] % 10 >= 5) scores[i] = 10 * (Math.floor(scores[i] / 10) + 1);
                    else scores[i] = 10 * Math.floor(scores[i] / 10);
                }
                let win_type = utils.WIN_TYPE_SCORE;
                if (scores[0] === 0) { scores[1] = 250; win_type = utils.WIN_TYPE_CAPO };
                if (scores[1] === 0) { scores[0] = 250; win_type = utils.WIN_TYPE_CAPO };

                const tierce_scores = utils.calculateTierceScores(G.tierces, G.asset);

                // add belote & re-belote
                for (let p = 0; p < 4; p++) {
                    if (_.includes(G.played[p], `${G.asset}11`) && _.includes(G.played[p], `${G.asset}12`)) {
                        tierce_scores[p % 2] += 20;
                        break;
                    }
                }
                // ------

                const buyer = G.buyer % 2, other = (G.buyer + 1) % 2;

                scores[0] += tierce_scores[0];
                scores[1] += tierce_scores[1];

                if (scores[buyer] < scores[other]) {
                    scores[other] = ((scores[buyer] > tierce_scores[buyer]) ? 160 : 250) + tierce_scores[buyer] + tierce_scores[other];
                    scores[buyer] = 0;
                }

                total_scores[buyer] += scores[buyer];
                total_scores[other] += scores[other];

                let winner = null;
                let pending_score_equals = G.pending_score_equals;
                if (scores[buyer] > scores[other]) {
                    winner = buyer;
                } else if (scores[buyer] < scores[other]) {
                    winner = other;
                    win_type = (win_type === utils.WIN_TYPE_SCORE) ? utils.WIN_TYPE_DKHOL : win_type;
                }

                if (winner !== null) {
                    if (pending_score_equals) total_scores[winner] += pending_score_equals;
                    pending_score_equals = 0;
                } else {
                    pending_score_equals += scores[0];
                }

                return {
                    ...G, scores: scores, total_scores: total_scores, party_over: {
                        winner,
                        win_type,
                    },
                    pending_score_equals: pending_score_equals,
                    first_player: ((G.first_player + 1) % 4),
                    should_continue: 0,
                };
            },
            next: 'continue',
        },
        continue: {
            moves: {
                continue: (G, ctx) => {
                    const should_continue = G.should_continue;
                    return { ...G, should_continue: should_continue + 1 };
                },
            },
            turn: {
                order: {
                    first: (G, ctx) => G.first_player,
                    next: (G, ctx) => (ctx.playOrderPos + 1) % 4,
                },
            },
            endIf: (G, ctx) => {
                if (G.should_continue > 0) return { end: true };
            },
            onEnd: (G, ctx) => {
                return {
                    ...G,
                    ...(newGame()),
                };
            },
            next: 'choose_asset',
        },
        endIf: (G, ctx) => false,
    },

    events: {
        endGame: false,
    },

    playerView: (G, ctx, playerID) => {
        let hands = _.cloneDeep(G.hands);
        for (let opponentID in G.playerIdx) {
            if (opponentID === playerID) continue;
            let idx = utils.playerIDtoIdx(G, opponentID);
            hands[idx] = Array(hands[idx].length).fill(0);
        }
        return {
            deck: Array(G.deck.length).fill(0),
            asset: G.asset,
            table: G.table,
            hands: hands,
            scores: G.scores,

            buyer: G.buyer,

            last_winner: G.last_winner,
            first_played_type: G.first_played_type,

            played: G.played,
            tierces: G.tierces,

            playerIdx: G.playerIdx,

            party_over: G.party_over,
            total_scores: G.total_scores,
            first_player: G.first_player,
            pending_score_equals: G.pending_score_equals,
            party_turn: G.party_turn,
            should_continue: G.should_continue,
        }
    },

    setup: (ctx) => {
        let game = newGame();
        let k = 0, playerIdx = {};
        for (let id of ctx.playOrder) {
            playerIdx[id] = k;
            k++;
        }
        return {
            ...game,
            playerIdx: playerIdx,
            total_scores: [0, 0],
            pending_score_equals: 0,
            first_player: 0,
        };
    },

    ai: {
        enumerate: ai.enumerate,
    },
};



export default Belote;