import _ from 'lodash';

let TYPES = ["c", "o", "b", "e"];
let DECK = [];
for (let t of TYPES) {
    DECK.push(`${t}${(1).toString().padStart(2, '0')}`);
    for (let i = 4; i <= 7; i++) DECK.push(`${t}${i.toString().padStart(2, '0')}`);
    for (let i = 10; i <= 12; i++) DECK.push(`${t}${i.toString().padStart(2, '0')}`);
}

const WIN_TYPE_DKHOL = "dkhol";
const WIN_TYPE_CAPO = "capo";
const WIN_TYPE_SCORE = "";

TYPES = _.sortBy(TYPES);
DECK = _.sortBy(DECK);

let deckIdx = {};
let i = 0;
for (let card of DECK) {
    deckIdx[card] = i;
    i++;
}

const scoreCard = (asset, card) => {
    const t = card.substr(0, 1), n = parseInt(card.substr(1), 10);

    if (t === asset) {
        switch (n) {
            case 6:
                return 14;
            case 10:
                return 20;
            default:
                break;
        }
    }

    switch (n) {
        case 1:
            return 11;
        case 7:
            return 10;
        case 10:
            return 2;
        case 11:
            return 3;
        case 12:
            return 4;
        default:
            return 0;
    }
}

const virtualScoreCard = (asset, first_played, card) => {
    if (!card) return -1000;
    const t = card.substr(0, 1), n = parseInt(card.substr(1), 10);
    let s = scoreCard(asset, card);
    if (s === 0) s = n - 5;

    if (t === asset) s += 100;
    else if (t === first_played) s += 50;

    return s;
}

const getTableWinner = (G, table) => {
    const { asset, first_played_type } = G;

    let max = 0;
    for (let i = 1; i < 4; i++) {
        if (virtualScoreCard(asset, first_played_type, table[i]) > virtualScoreCard(asset, first_played_type, table[max])) {
            max = i;
        }
    }

    return max;
}

const isTableAllEmpty = (table) => (table[0] === 0 && table[1] === 0 && table[2] === 0 && table[3] === 0);
const isTableAllFilled = (table) => (table[0] !== 0 && table[1] !== 0 && table[2] !== 0 && table[3] !== 0);

const canDeclareTierce = (G) => {
    const playersWith7Cards = _.filter(G.hands, (h) => h.length === 7).length;
    const playersWithMoreThan7Cards = _.filter(G.hands, (h) => h.length > 7).length;
    return playersWith7Cards && !playersWithMoreThan7Cards;
}

const tierceSortOrder = (card) => {
    const t = card.substr(0, 1);
    let n = parseInt(card.substr(1), 10);
    let offset = 0;
    if (t === "e") offset = 15;
    else if (t === "o") offset = 30;
    else if (t === "b") offset = 45;

    if (n === 1) return offset + 11;
    if (n < 10) return offset + n;
    return offset + n - 2;
}

const isValidTierceSuivi = (tierce) => {
    if (tierce.length < 3) return false;
    for (let i = 1; i < tierce.length; i++) {
        if (tierceSortOrder(tierce[i]) - tierceSortOrder(tierce[i - 1]) !== 1) return false;
    }
    return true;
}

const isValidTierceQuadruplets = (tierce) => {
    return tierce.length === 4 && (parseInt(tierce[0].substr(1), 10) > 5 || parseInt(tierce[0].substr(1), 10) === 1) &&
        tierce[0].substr(1) === tierce[1].substr(1) &&
        tierce[0].substr(1) === tierce[2].substr(1) &&
        tierce[0].substr(1) === tierce[3].substr(1);
}

const isValidTierce = (tierce) => isValidTierceSuivi(tierce) || isValidTierceQuadruplets(tierce);

const scoreQuadruplet = (quadruplet) => {
    const n = parseInt(quadruplet[0].substr(1), 10);
    if (n === 6) return 150;
    if (n === 10) return 200;
    return 100;
}

const scoreTierce = (tierce) => {
    if (tierce.length === 3) return 20;
    if (tierce.length === 4) return 50;
    return 100;
}

const tierceCmp = (asset) => {
    return (a, b) => {
        const ta = a[0].substr(0, 1), tb = b[0].substr(0, 1);

        // length
        if (a.length < b.length) return -1;
        if (a.length > b.length) return 1;

        // last card order
        a = _.sortBy(a, tierceSortOrder);
        let aLastCard = parseInt(a[a.length - 1].substr(1), 10);
        aLastCard = (aLastCard === 1) ? 13 : aLastCard;
        b = _.sortBy(b, tierceSortOrder);
        let bLastCard = parseInt(b[b.length - 1].substr(1), 10);
        bLastCard = (bLastCard === 1) ? 13 : bLastCard;
        if (aLastCard < bLastCard) return -1;
        if (aLastCard > bLastCard) return 1;

        if (ta !== asset && tb === asset) return -1;
        if (ta === asset && tb !== asset) return 1;

        return 0;
    }
}

const calculateTierceScores = (tierces, asset) => {
    let quadruplets = _.map(tierces, ts => _.filter(ts, t => isValidTierceQuadruplets(t)));
    quadruplets = [_.concat(quadruplets[0], quadruplets[2]), _.concat(quadruplets[1], quadruplets[3])];
    let suivis = _.map(tierces, ts => _.filter(ts, t => isValidTierceSuivi(t)));
    suivis = [_.concat(suivis[0], suivis[2]), _.concat(suivis[1], suivis[3])];
    const compFunc = tierceCmp(asset);
    suivis[0] = suivis[0].sort(compFunc);
    suivis[1] = suivis[1].sort(compFunc);
    if (suivis[0].length && suivis[1].length) {
        if (compFunc(suivis[0][suivis[0].length - 1], suivis[1][suivis[1].length - 1]) > 0) {
            suivis[1] = [];
        } else if (compFunc(suivis[0][suivis[0].length - 1], suivis[1][suivis[1].length - 1]) < 0) {
            suivis[0] = [];
        }
    }

    let tierce_scores = [0, 0];
    tierce_scores[0] = _.sumBy(quadruplets[0], scoreQuadruplet) + _.sumBy(suivis[0], scoreTierce);
    tierce_scores[1] = _.sumBy(quadruplets[1], scoreQuadruplet) + _.sumBy(suivis[1], scoreTierce);

    return tierce_scores;
}

const PLAY_INVALID_MOVE_INVALID_CARD = "invalid";
const PLAY_INVALID_MOVE_SHOULD_PLAY_FIRST_TYPE = "first";
const PLAY_INVALID_MOVE_SHOULD_PLAY_HIGHER_ASSET = "higher_asset";
const PLAY_INVALID_MOVE_SHOULD_FIER = "fier";
const PLAY_INVALID_MOVE_SHOULD_FIER_WITH_HIGHER = "higher_fier";

const isValidPlayCardMove = (G, playerIdx, card) => {
    let hands = G.hands;
    let table = G.table;
    let hand = hands[playerIdx];
    if (!_.includes(hand, card)) {
        return { valid: false, reason: PLAY_INVALID_MOVE_INVALID_CARD };
    }

    const t = card.substr(0, 1);

    // if table is empty you can play anything
    if (!(isTableAllEmpty(table) || isTableAllFilled(table))) {
        let player_has_first_type = Boolean(_.find(hand, (c) => c.substr(0, 1) === G.first_played_type));
        // player has first type, they should always play it
        if (t !== G.first_played_type && player_has_first_type) {
            return { valid: false, reason: PLAY_INVALID_MOVE_SHOULD_PLAY_FIRST_TYPE };
        }
        // if first played is asset and player has a higher asset card than all on table
        else if (G.first_played_type === G.asset) {
            let assets = _.filter(hand, (c) => c.substr(0, 1) === G.asset);
            if (assets.length) {
                let max_card_on_table = _.maxBy(_.filter(table, (c) => Boolean(c)), (c) => (c.substr(0, 1) === G.asset) ? virtualScoreCard(G.asset, G.first_played_type, c) : 0);
                let found_higher = _.filter(assets, (c) => virtualScoreCard(G.asset, G.first_played_type, c) > virtualScoreCard(G.asset, G.first_played_type, max_card_on_table))
                if (found_higher.length && !_.includes(found_higher, card)) {
                    return { valid: false, reason: PLAY_INVALID_MOVE_SHOULD_PLAY_HIGHER_ASSET };
                }
            }
        }
        // if opponent is winning
        else if (G.first_played_type !== G.asset && !player_has_first_type && getTableWinner(G, table) % 2 !== playerIdx % 2) {
            let assets = _.filter(hand, (c) => c.substr(0, 1) === G.asset);
            if (assets.length && t !== G.asset) {
                // if I have asset cards and didn't play any of them
                let opponents_assets_on_table = _.map(_.filter([0, 1, 2, 3], (i) => (i % 2 !== playerIdx % 2) && table[i] && table[i].substr(0, 1) === G.asset), i => table[i]);
                if (opponents_assets_on_table.length) {
                    // if opponent played asset, check if I have higher, then I should play it
                    let max_opponent_asset_card = _.maxBy(opponents_assets_on_table, (c) => virtualScoreCard(G.asset, G.first_played_type, c));
                    let max_opponent_asset_score = virtualScoreCard(G.asset, G.first_played_type, max_opponent_asset_card);
                    let found_higher = _.filter(assets, (c) => virtualScoreCard(G.asset, G.first_played_type, c) > max_opponent_asset_score);
                    if (found_higher.length && !_.includes(found_higher, card)) {
                        return { valid: false, reason: PLAY_INVALID_MOVE_SHOULD_FIER_WITH_HIGHER };
                    }
                } else {
                    // otherwise, if opponent didn't play asset, I should play asset
                    return { valid: false, reason: PLAY_INVALID_MOVE_SHOULD_FIER };
                }
            }
        }
    }

    return { valid: true };
}

const isValidTierceDeclare = (G, ctx, tierce) => {
    let playerIdx = G.playerIdx[ctx.currentPlayer];
    if (!canDeclareTierce(G)) {
        return false;
    }

    for (let card of tierce) {
        if (!_.includes(G.hands[playerIdx], card) && !_.includes(G.played[playerIdx], card)) {
            return false;
        }
    }

    let _tierce = _.sortBy(tierce, tierceSortOrder);
    if (!isValidTierce(_tierce)) {
        return false;
    }

    if (_.find(G.tierces[playerIdx], t => _.isEqual(_tierce, t))) {
        return false;
    }

    if (isValidTierceSuivi(_tierce) && _.find(G.tierces[playerIdx], t => isValidTierceSuivi(t) && !_.isEmpty(_.intersection(t, _tierce)))) {
        return false;
    }

    return true;
}

const Utils = {
    DECK,
    TYPES,
    WIN_TYPE_DKHOL,
    WIN_TYPE_CAPO,
    WIN_TYPE_SCORE,
    PLAY_INVALID_MOVE_INVALID_CARD,
    PLAY_INVALID_MOVE_SHOULD_PLAY_FIRST_TYPE,
    PLAY_INVALID_MOVE_SHOULD_PLAY_HIGHER_ASSET,
    PLAY_INVALID_MOVE_SHOULD_FIER,
    PLAY_INVALID_MOVE_SHOULD_FIER_WITH_HIGHER,
    deckIdx,
    playerIDtoIdx: (G, playerID) => G.playerIdx[playerID],
    scoreCard: scoreCard,
    virtualScoreCard: virtualScoreCard,
    isTableAllEmpty: isTableAllEmpty,
    isTableAllFilled: isTableAllFilled,
    getTableWinner: getTableWinner,
    canDeclareTierce: canDeclareTierce,
    tierceSortOrder: tierceSortOrder,
    isValidTierceDeclare: isValidTierceDeclare,
    isValidTierce: isValidTierce,
    isValidTierceSuivi: isValidTierceSuivi,
    isValidTierceQuadruplets: isValidTierceQuadruplets,
    scoreQuadruplet: scoreQuadruplet,
    scoreTierce: scoreTierce,
    tierceCmp: tierceCmp,
    isValidPlayCardMove: isValidPlayCardMove,
    calculateTierceScores: calculateTierceScores,
};

export default Utils;