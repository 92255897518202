import React from 'react';
import { View, Text, Dimensions, Image, TouchableHighlight } from 'react-native';
import Constants from 'expo-constants';
import IMG_SRCS from './img';


const WIN_DIM = Dimensions.get('window');
const SCREEN_WIDTH = Math.floor(WIN_DIM.width);
const SCREEN_HEIGHT = Math.floor(WIN_DIM.height);


// --- CONSTANTS

const PADDING = 5;
const CARD_BORDER_SIZE = 1;
const HAND_BORDER_SIZE = 2;
const ORIGINAL_CARD_WIDTH = 200;
const ORIGINAL_CARD_HEIGHT = 318;
const CARD_SIZE_RATIO = ORIGINAL_CARD_HEIGHT / ORIGINAL_CARD_WIDTH;
const SHOWN_CARD_WIDTH_PCT = 0.6;
const SMALL_CARD_SIZE_PCT = 0.8;

const HEADER_MENU_HEIGHT = 60;

// --- PLAYER NAME

const PLAYER_NAME_HEIGHT = 30;

// --- CARDS

const CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN = (SCREEN_WIDTH - 2 * PADDING + 7 * CARD_BORDER_SIZE - 6 * HAND_BORDER_SIZE) / ((7 * SHOWN_CARD_WIDTH_PCT + 1) * ORIGINAL_CARD_WIDTH);
const CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN = (SCREEN_HEIGHT - (Constants.statusBarHeight || 0) - 2 * PADDING - HEADER_MENU_HEIGHT - (2 * PLAYER_NAME_HEIGHT + 6 * PADDING + 6 * HAND_BORDER_SIZE + (2 * (7 * SHOWN_CARD_WIDTH_PCT + 1) - 3) * CARD_BORDER_SIZE)) / (ORIGINAL_CARD_WIDTH * (CARD_SIZE_RATIO * (SMALL_CARD_SIZE_PCT + 1) + SMALL_CARD_SIZE_PCT * (7 * SHOWN_CARD_WIDTH_PCT + 1)));
const CARD_SIZE_PCT = Math.min(
    1,
    CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN,
    CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN,
);

const CARD_WIDTH = Math.floor(CARD_SIZE_PCT * 0.9 * ORIGINAL_CARD_WIDTH);
const CARD_HEIGHT = Math.floor(CARD_SIZE_PCT * 0.9 * ORIGINAL_CARD_HEIGHT);
const CARD_SHOWN_WIDTH = CARD_WIDTH * 0.4;



const GameCardsChoice = ({ setSettingsCardsAR, setSettingsCardsFR, settings }) => (
    <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Text style={{ marginTop: 10, color: "white", marginBottom: 5 }}>Jeu de cartes</Text>
        <View style={{ marginBottom: 10, flexDirection: "row", borderWidth: 1, borderColor: "white" }}>
            <TouchableHighlight onPress={setSettingsCardsAR}>
                <View style={{ alignItems: "center", justifyContent: "center", padding: 5, borderWidth: 1, borderColor: ((settings.cards === "AR") ? "white" : "rgb(83, 64, 47)"), backgroundColor: ((settings.cards === "AR") ? "rgb(168, 155, 120)" : null) }}>
                    <View style={{ flexDirection: "row", width: CARD_WIDTH * (0.4 * 3 + 1) }} >
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_AR_e10_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_AR_c11_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_AR_o12_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_AR_b01_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                    </View>
                </View>
            </TouchableHighlight>
            <View style={{ width: 5 }} />
            <TouchableHighlight onPress={setSettingsCardsFR}>
                <View style={{ alignItems: "center", justifyContent: "center", padding: 5, borderWidth: 1, borderColor: ((settings.cards === "FR") ? "white" : "rgb(83, 64, 47)"), backgroundColor: ((settings.cards === "FR") ? "rgb(168, 155, 120)" : null) }}>
                    <View style={{ flexDirection: "row", width: CARD_WIDTH * (0.4 * 3 + 1) }} >
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_FR_e10_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_FR_c11_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_FR_o12_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                        <View style={{
                            width: CARD_SHOWN_WIDTH,
                            height: CARD_HEIGHT,
                        }}>
                            <Image source={IMG_SRCS['CARD_FR_b01_IMG']} style={{ width: CARD_WIDTH, height: CARD_HEIGHT, resizeMode: "contain" }} />
                        </View>
                    </View>
                </View>
            </TouchableHighlight>
        </View>
    </View>
)

export default GameCardsChoice;