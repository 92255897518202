import React from 'react';
import { View, Text, Image, Dimensions } from 'react-native';
import Constants from 'expo-constants';

import IMG_SRCS from './img';


const WIN_DIM = Dimensions.get('window');
const SCREEN_WIDTH = Math.floor(WIN_DIM.width);
const SCREEN_HEIGHT = Math.floor(WIN_DIM.height);

// --- CONSTANTS

const PADDING = 5;
const CARD_BORDER_SIZE = 1;
const HAND_BORDER_SIZE = 2;
const ORIGINAL_CARD_WIDTH = 200;
const ORIGINAL_CARD_HEIGHT = 318;
const CARD_SIZE_RATIO = ORIGINAL_CARD_HEIGHT / ORIGINAL_CARD_WIDTH;
const SHOWN_CARD_WIDTH_PCT = 0.6;
const SMALL_CARD_SIZE_PCT = 0.8;

const HEADER_MENU_HEIGHT = 60;

// --- PLAYER NAME

const PLAYER_NAME_HEIGHT = 30;

// --- CARDS

const CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN = (SCREEN_WIDTH - 2 * PADDING + 7 * CARD_BORDER_SIZE - 6 * HAND_BORDER_SIZE) / ((7 * SHOWN_CARD_WIDTH_PCT + 1) * ORIGINAL_CARD_WIDTH);
const CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN = (SCREEN_HEIGHT - (Constants.statusBarHeight || 0) - 2 * PADDING - HEADER_MENU_HEIGHT - (2 * PLAYER_NAME_HEIGHT + 6 * PADDING + 6 * HAND_BORDER_SIZE + (2 * (7 * SHOWN_CARD_WIDTH_PCT + 1) - 3) * CARD_BORDER_SIZE)) / (ORIGINAL_CARD_WIDTH * (CARD_SIZE_RATIO * (SMALL_CARD_SIZE_PCT + 1) + SMALL_CARD_SIZE_PCT * (7 * SHOWN_CARD_WIDTH_PCT + 1)));
const CARD_SIZE_PCT = Math.min(
    1,
    CARD_SIZE_PCT_IF_FULL_HAND_PLAYER_SHOWN,
    CARD_SIZE_PCT_IF_FULL_HEIGHT_SHOWN,
);





const Rules = ({ settings }) => {
    const TYPE_O_NAME = (settings.cards === "AR") ? "or" : "coeur";
    return (
        <View>
            <Text style={{ color: "white" }}>La belote se joue à 4 joueurs répartis en deux équipes: Nord/Sud contre Est/Ouest.</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>La distribution des cartes </Text>
            <Text style={{ color: "white" }}>{`On utilise un jeu de 32 cartes.
Au début de chaque partie, 5 cartes sont distribuées à chacun, et une carte est placée en évidence sur le tapis. Le tour de prise peut commencer.`}</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>La prise</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, marginBottom: 10 }}>Tour de prise</Text>
            <Text style={{ color: "white" }}>Les joueurs s’expriment chacun à leur tour. Chaque joueur peut:</Text>
            <Text style={{ color: "white", marginLeft: 15 }}>{"- soit passer son tour"}</Text>
            <Text style={{ color: "white", marginLeft: 15 }}>{"- soit choisir l'atout: « prendre »"}</Text>
            <Text style={{ color: "white" }}>{`Pour la première tournée de prise, l’atout ne peut être que celui de la couleur de la carte posée sur le tapis. Si les 4 joueurs passent, un second tour commence où l’atout peut être choisi parmi les trois autres couleurs.
Si les 4 joueurs passent de nouveau, le tour est terminé. les cartes sont redistribuées et une nouvelle partie est lancée.
Sinon, si un joueur décide de prendre, le tour de prise est terminé et on distribue le reste des cartes: 3 nouvelles cartes pour chaque joueur à l'exception du preneur qui prend la carte sur le tapis + 2 nouvelles cartes.`}</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>Le jeu de la carte</Text>
            <Text style={{ color: "white" }}>{`Après le tour de prise, les preneurs vont tenter de marquer plus de points que leurs adversaires au cours du jeu de la carte.
Le premier joueur joue la carte de son choix; la couleur de cette carte fixe la couleur demandée. Les autres jouent chacun à leur tour en respectant les règles suivantes:`}</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, marginBottom: 10 }}>Règles</Text>
            <Text style={{ color: "white", marginLeft: 15 }}>{`1. On doit toujours fournir la couleur demandée à l’entame si l’on en possède`}</Text>
            <Text style={{ color: "white", marginLeft: 15 }}>{`2. Si l’on ne possède pas de carte dans la couleur demandée, deux cas se présentent:`}</Text>
            <Text style={{ color: "white", marginLeft: 30 }}>{`- le partenaire est maître (il a joué la meilleure carte sur le tapis): on peut alors jouer n’importe quelle carte.`}</Text>
            <Text style={{ color: "white", marginLeft: 30 }}>{`- le partenaire n’est pas maître ou n’a pas encore joué: on est tenu de « couper » en jouant un atout si l’on en possède, on peut jouer n’importe quelle carte.`}</Text>
            <Text style={{ color: "white", marginLeft: 15 }}>{`3. Si la couleur demandée est l'atout, on est obligé, si possible, de « monter » à l’atout, c’est-à-dire jouer un atout plus fort que la meilleure carte atout déjà présente sur le tapis.`}</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, marginBottom: 10 }}>Ordre et valeur des cartes</Text>
            <Text style={{ color: "white" }}>L’ordre et la valeur des cartes varient selon qu’il s’agisse de l’atout ou d’une autre couleur. Un atout est toujours plus fort qu’une carte à une autre couleur.</Text>
            <View style={{ marginTop: 5 }} >
                <Text style={{ color: "white", textAlign: "center", fontWeight: "bold" }}>Atout ({TYPE_O_NAME} comme exemple)</Text>
                <View style={{ padding: 5, borderWidth: 1, borderColor: 'white' }} >
                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o10_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>20</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o06_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>14</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o01_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>11</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o07_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>10</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 15 }} >
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o12_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>4</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o11_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>3</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o05_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>0</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_o04_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>0</Text>
                        </View>
                    </View>
                </View>
                <Text style={{ color: "white", marginTop: 15, textAlign: "center", fontWeight: "bold" }}>Autre que atout</Text>
                <View style={{ padding: 5, borderWidth: 1, borderColor: 'white' }} >
                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b01_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>11</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b07_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>10</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b12_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>4</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b11_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>3</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 15 }} >
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b10_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>2</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b06_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>0</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b05_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>0</Text>
                        </View>
                        <View style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, alignItems: "center", justifyContent: "center" }}>
                            <Image source={IMG_SRCS[`CARD_${settings.cards}_b04_IMG`]} style={{ width: CARD_SIZE_PCT * ORIGINAL_CARD_WIDTH, height: CARD_SIZE_PCT * ORIGINAL_CARD_HEIGHT, resizeMode: "contain", borderColor: "grey", borderWidth: 1 }} />
                            <Text style={{ color: "white", marginTop: 5 }}>0</Text>
                        </View>
                    </View>
                </View>
            </View>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, marginBottom: 10 }}>Fin du pli</Text>
            <Text style={{ color: "white" }}>Le pli est remporté par le joueur qui a joué la plus forte carte à l’atout ou, à défaut, à la couleur demandée. L’entame du pli suivant est réalisée par le joueur qui vient de remporter le dernier pli. Lorsque toutes les cartes ont été jouées, on procède au calcul du score.</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>Annonces</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, marginBottom: 10 }}>Suivis et Carrés</Text>
            <Text style={{ color: "white" }}>{`Avant de jouer le deuxième tour, un joueur peu faire une annonce de un ou plusieurs Suivis ou Carrés s'il en possède.`}</Text>
            <Text style={{ color: "white", marginTop: 5, fontWeight: "bold" }}>{`Suivi: au moins 3 cartes de la même couleur qui se suivent en numéro`}</Text>
            <Text style={{ color: "white", marginLeft: 25 }}>{`- 3 cartes: 20 points`}</Text>
            <Text style={{ color: "white", marginLeft: 25 }}>{`- 4 cartes: 50 points`}</Text>
            <Text style={{ color: "white", marginLeft: 25 }}>{`- 5 cartes ou plus: 100 points`}</Text>
            <Text style={{ color: "white", marginTop: 5, fontWeight: "bold" }}>{`Carré: 4 cartes du même numéro`}</Text>
            <Text style={{ color: "white", marginLeft: 25 }}>{`- Carré de ${settings.cards === "AR" ? "10" : "Valets"}: 200 points`}</Text>
            <Text style={{ color: "white", marginLeft: 25 }}>{`- Carré de ${settings.cards === "AR" ? "6" : "9"}: 150 points`}</Text>
            <Text style={{ color: "white", marginLeft: 25 }}>{`- Carré de ${settings.cards === "AR" ? "7, 11, ou 12" : "10, Damme, ou Roi"}: 100 points`}</Text>
            <Text style={{ color: "white", marginLeft: 25 }}>{`- Carré de 4 ou 5: 0 points`}</Text>
            <Text style={{ color: "white" }}>Dans les suites, à longueur égale, c’est la hauteur qui les départage (par exemple, une tierce au Roi est plus forte qu’une tierce à la Dame). À hauteur et longueur égale, c’est la suite à l’atout qui sera la plus forte.</Text>
            <Text style={{ color: "white", fontSize: 18, marginTop: 10, marginBottom: 10 }}>Belote et Rebelote</Text>
            <Text style={{ color: "white" }}>{`Si un joueur possède le ${settings.cards === "AR" ? "11" : "Damme"} (belote) et le ${settings.cards === "AR" ? "12" : "Roi"} (rebelote) d'atout, 20 points bonus sont attribuées à son équipe à la fin de la partie.`}</Text>
            <Text style={{ color: "white", fontSize: 25, marginTop: 15, marginBottom: 10 }}>Calcul de score</Text>
            <Text style={{ color: "white" }}>{`Lorsque le dernier pli vient de se jouer, chaque camp procède au décompte de ses points en additionnant les points des cartes qu’il a remportées aux points des annonces éventuelles.
Un bonus spécial de 10 points appelé « Dix de Der » est attribué au camp qui remporte le dernier pli. 
3 cas sont alors possibles:`}</Text>
            <Text style={{ color: "white", fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>1. Contrat réussi</Text>
            <Text style={{ color: "white" }}>Si les preneurs totalisent plus de points que la défense, le contrat est rempli, leur score correspond à la somme des points réalisés (plis+belote+annonces). Leurs adversaires marquent également leurs points (plis+belote+annonces).</Text>
            <Text style={{ color: "white", fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>2. Chute</Text>
            <Text style={{ color: "white" }}>Si les preneurs totalisent moins de points que la défense, le contrat est chuté, les preneurs ne marquent rien, excepté la belote qui est imprenable. Leurs adversaires marquent 162 points de chute + leurs annonces + les annonces des preneurs. Les preneurs sont alors « Dedans ».</Text>
            <Text style={{ color: "white", fontWeight: "bold", marginTop: 10, marginBottom: 10 }}>3. Litige</Text>
            <Text style={{ color: "white" }}>En cas d’égalité parfaite au score (Exemple: 81-81), il y a « litige »: la défense marque ses points, mais les points des preneurs sont remis en jeu et seront offerts en bonus aux vainqueurs de la prochaine prise.</Text>
        </View>
    )
}


export default Rules;