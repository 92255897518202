import CARD_b01_IMG from '../assets/png/cards/b01.png';
import CARD_b06_IMG from '../assets/png/cards/b06.png';
import CARD_b11_IMG from '../assets/png/cards/b11.png';
import CARD_c04_IMG from '../assets/png/cards/c04.png';
import CARD_c07_IMG from '../assets/png/cards/c07.png';
import CARD_c12_IMG from '../assets/png/cards/c12.png';
import CARD_e05_IMG from '../assets/png/cards/e05.png';
import CARD_e10_IMG from '../assets/png/cards/e10.png';
import CARD_o01_IMG from '../assets/png/cards/o01.png';
import CARD_o06_IMG from '../assets/png/cards/o06.png';
import CARD_o11_IMG from '../assets/png/cards/o11.png';
import CARD_b04_IMG from '../assets/png/cards/b04.png';
import CARD_b07_IMG from '../assets/png/cards/b07.png';
import CARD_b12_IMG from '../assets/png/cards/b12.png';
import CARD_c05_IMG from '../assets/png/cards/c05.png';
import CARD_c10_IMG from '../assets/png/cards/c10.png';
import CARD_e01_IMG from '../assets/png/cards/e01.png';
import CARD_e06_IMG from '../assets/png/cards/e06.png';
import CARD_e11_IMG from '../assets/png/cards/e11.png';
import CARD_o04_IMG from '../assets/png/cards/o04.png';
import CARD_o07_IMG from '../assets/png/cards/o07.png';
import CARD_o12_IMG from '../assets/png/cards/o12.png';
import CARD_b05_IMG from '../assets/png/cards/b05.png';
import CARD_b10_IMG from '../assets/png/cards/b10.png';
import CARD_c01_IMG from '../assets/png/cards/c01.png';
import CARD_c06_IMG from '../assets/png/cards/c06.png';
import CARD_c11_IMG from '../assets/png/cards/c11.png';
import CARD_e04_IMG from '../assets/png/cards/e04.png';
import CARD_e07_IMG from '../assets/png/cards/e07.png';
import CARD_e12_IMG from '../assets/png/cards/e12.png';
import CARD_o05_IMG from '../assets/png/cards/o05.png';
import CARD_o10_IMG from '../assets/png/cards/o10.png';

import CARD_FR_b01_IMG from '../assets/png/cards-fr/b01.png';
import CARD_FR_b06_IMG from '../assets/png/cards-fr/b06.png';
import CARD_FR_b11_IMG from '../assets/png/cards-fr/b11.png';
import CARD_FR_c04_IMG from '../assets/png/cards-fr/c04.png';
import CARD_FR_c07_IMG from '../assets/png/cards-fr/c07.png';
import CARD_FR_c12_IMG from '../assets/png/cards-fr/c12.png';
import CARD_FR_e05_IMG from '../assets/png/cards-fr/e05.png';
import CARD_FR_e10_IMG from '../assets/png/cards-fr/e10.png';
import CARD_FR_o01_IMG from '../assets/png/cards-fr/o01.png';
import CARD_FR_o06_IMG from '../assets/png/cards-fr/o06.png';
import CARD_FR_o11_IMG from '../assets/png/cards-fr/o11.png';
import CARD_FR_b04_IMG from '../assets/png/cards-fr/b04.png';
import CARD_FR_b07_IMG from '../assets/png/cards-fr/b07.png';
import CARD_FR_b12_IMG from '../assets/png/cards-fr/b12.png';
import CARD_FR_c05_IMG from '../assets/png/cards-fr/c05.png';
import CARD_FR_c10_IMG from '../assets/png/cards-fr/c10.png';
import CARD_FR_e01_IMG from '../assets/png/cards-fr/e01.png';
import CARD_FR_e06_IMG from '../assets/png/cards-fr/e06.png';
import CARD_FR_e11_IMG from '../assets/png/cards-fr/e11.png';
import CARD_FR_o04_IMG from '../assets/png/cards-fr/o04.png';
import CARD_FR_o07_IMG from '../assets/png/cards-fr/o07.png';
import CARD_FR_o12_IMG from '../assets/png/cards-fr/o12.png';
import CARD_FR_b05_IMG from '../assets/png/cards-fr/b05.png';
import CARD_FR_b10_IMG from '../assets/png/cards-fr/b10.png';
import CARD_FR_c01_IMG from '../assets/png/cards-fr/c01.png';
import CARD_FR_c06_IMG from '../assets/png/cards-fr/c06.png';
import CARD_FR_c11_IMG from '../assets/png/cards-fr/c11.png';
import CARD_FR_e04_IMG from '../assets/png/cards-fr/e04.png';
import CARD_FR_e07_IMG from '../assets/png/cards-fr/e07.png';
import CARD_FR_e12_IMG from '../assets/png/cards-fr/e12.png';
import CARD_FR_o05_IMG from '../assets/png/cards-fr/o05.png';
import CARD_FR_o10_IMG from '../assets/png/cards-fr/o10.png';

import CARD_cover_IMG from '../assets/png/cover.png';

import ASSET_o_IMG from '../assets/png/o.png';
import ASSET_e_IMG from '../assets/png/e.png';
import ASSET_c_IMG from '../assets/png/c.png';
import ASSET_b_IMG from '../assets/png/b.png';

import ASSET_FR_o_IMG from '../assets/png/o-fr.png';
import ASSET_FR_e_IMG from '../assets/png/e-fr.png';
import ASSET_FR_c_IMG from '../assets/png/c-fr.png';
import ASSET_FR_b_IMG from '../assets/png/b-fr.png';

import backgroundIMG from '../assets/back.png';

import headerIMG from '../assets/png/header.png';
import fezIMG from '../assets/png/fez.png';


const IMGS_SRC = {
    "CARD_AR_b01_IMG": CARD_b01_IMG,
    "CARD_AR_b06_IMG": CARD_b06_IMG,
    "CARD_AR_b11_IMG": CARD_b11_IMG,
    "CARD_AR_c04_IMG": CARD_c04_IMG,
    "CARD_AR_c07_IMG": CARD_c07_IMG,
    "CARD_AR_c12_IMG": CARD_c12_IMG,
    "CARD_AR_e05_IMG": CARD_e05_IMG,
    "CARD_AR_e10_IMG": CARD_e10_IMG,
    "CARD_AR_o01_IMG": CARD_o01_IMG,
    "CARD_AR_o06_IMG": CARD_o06_IMG,
    "CARD_AR_o11_IMG": CARD_o11_IMG,
    "CARD_AR_b04_IMG": CARD_b04_IMG,
    "CARD_AR_b07_IMG": CARD_b07_IMG,
    "CARD_AR_b12_IMG": CARD_b12_IMG,
    "CARD_AR_c05_IMG": CARD_c05_IMG,
    "CARD_AR_c10_IMG": CARD_c10_IMG,
    "CARD_AR_e01_IMG": CARD_e01_IMG,
    "CARD_AR_e06_IMG": CARD_e06_IMG,
    "CARD_AR_e11_IMG": CARD_e11_IMG,
    "CARD_AR_o04_IMG": CARD_o04_IMG,
    "CARD_AR_o07_IMG": CARD_o07_IMG,
    "CARD_AR_o12_IMG": CARD_o12_IMG,
    "CARD_AR_b05_IMG": CARD_b05_IMG,
    "CARD_AR_b10_IMG": CARD_b10_IMG,
    "CARD_AR_c01_IMG": CARD_c01_IMG,
    "CARD_AR_c06_IMG": CARD_c06_IMG,
    "CARD_AR_c11_IMG": CARD_c11_IMG,
    "CARD_AR_e04_IMG": CARD_e04_IMG,
    "CARD_AR_e07_IMG": CARD_e07_IMG,
    "CARD_AR_e12_IMG": CARD_e12_IMG,
    "CARD_AR_o05_IMG": CARD_o05_IMG,
    "CARD_AR_o10_IMG": CARD_o10_IMG,

    "CARD_FR_b01_IMG": CARD_FR_b01_IMG,
    "CARD_FR_b06_IMG": CARD_FR_b06_IMG,
    "CARD_FR_b11_IMG": CARD_FR_b11_IMG,
    "CARD_FR_c04_IMG": CARD_FR_c04_IMG,
    "CARD_FR_c07_IMG": CARD_FR_c07_IMG,
    "CARD_FR_c12_IMG": CARD_FR_c12_IMG,
    "CARD_FR_e05_IMG": CARD_FR_e05_IMG,
    "CARD_FR_e10_IMG": CARD_FR_e10_IMG,
    "CARD_FR_o01_IMG": CARD_FR_o01_IMG,
    "CARD_FR_o06_IMG": CARD_FR_o06_IMG,
    "CARD_FR_o11_IMG": CARD_FR_o11_IMG,
    "CARD_FR_b04_IMG": CARD_FR_b04_IMG,
    "CARD_FR_b07_IMG": CARD_FR_b07_IMG,
    "CARD_FR_b12_IMG": CARD_FR_b12_IMG,
    "CARD_FR_c05_IMG": CARD_FR_c05_IMG,
    "CARD_FR_c10_IMG": CARD_FR_c10_IMG,
    "CARD_FR_e01_IMG": CARD_FR_e01_IMG,
    "CARD_FR_e06_IMG": CARD_FR_e06_IMG,
    "CARD_FR_e11_IMG": CARD_FR_e11_IMG,
    "CARD_FR_o04_IMG": CARD_FR_o04_IMG,
    "CARD_FR_o07_IMG": CARD_FR_o07_IMG,
    "CARD_FR_o12_IMG": CARD_FR_o12_IMG,
    "CARD_FR_b05_IMG": CARD_FR_b05_IMG,
    "CARD_FR_b10_IMG": CARD_FR_b10_IMG,
    "CARD_FR_c01_IMG": CARD_FR_c01_IMG,
    "CARD_FR_c06_IMG": CARD_FR_c06_IMG,
    "CARD_FR_c11_IMG": CARD_FR_c11_IMG,
    "CARD_FR_e04_IMG": CARD_FR_e04_IMG,
    "CARD_FR_e07_IMG": CARD_FR_e07_IMG,
    "CARD_FR_e12_IMG": CARD_FR_e12_IMG,
    "CARD_FR_o05_IMG": CARD_FR_o05_IMG,
    "CARD_FR_o10_IMG": CARD_FR_o10_IMG,

    "CARD_AR_cover_IMG": CARD_cover_IMG,

    "CARD_FR_cover_IMG": CARD_cover_IMG,

    "ASSET_AR_o_IMG": ASSET_o_IMG,
    "ASSET_AR_e_IMG": ASSET_e_IMG,
    "ASSET_AR_c_IMG": ASSET_c_IMG,
    "ASSET_AR_b_IMG": ASSET_b_IMG,

    "ASSET_FR_o_IMG": ASSET_FR_o_IMG,
    "ASSET_FR_e_IMG": ASSET_FR_e_IMG,
    "ASSET_FR_c_IMG": ASSET_FR_c_IMG,
    "ASSET_FR_b_IMG": ASSET_FR_b_IMG,

    "back": backgroundIMG,
    "headerIMG": headerIMG,
    "fezIMG": fezIMG,
};

export default IMGS_SRC;