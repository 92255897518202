import React from 'react';
import { Animated, Easing } from 'react-native';


class AnimateNumber extends React.Component {
    state = {
        value: new Animated.Value(0),
        displayed: 0,
    }

    componentDidMount() {
        this.state.value.addListener((v) => this.setState({
            displayed: Math.ceil(v.value),
        }));
        this.animateTo(this.props.value);
    }

    componentWillUnmount() {
        if (this.anim) this.anim.stop();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.animateTo(this.props.value);
        }
    }

    render() {
        return (
            <Animated.Text style={this.props.style || {}}>
                {this.state.displayed}
            </Animated.Text>
        );
    }

    animateTo = (v) => {
        if (this.anim) this.anim.stop();
        this.anim = Animated.timing(this.state.value, {
            toValue: v,
            duration: 2000,
            easing: Easing.out(Easing.exp),
        });
        this.anim.start(() => this.setState({
            displayed: v,
        }));
    }
}

export default AnimateNumber;