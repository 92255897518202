import React from 'react';
import { StyleSheet, View } from 'react-native';

import Lobby from './UI/Lobby';
import Privacy from './UI/Privacy';

let dummyWindow = {
  location: { pathname: "" },
}


let _window = (window.location) ? window : dummyWindow;


export default function App() {
  if (_window.location.pathname) {
    let split = _window.location.pathname.split("/");
    if (split.length > 1 && split[1] === "privacy") return <Privacy />;
  }
  return (
    <View style={styles.container} >
      <Lobby />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  }
});
