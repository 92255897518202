import React from 'react';

const ROOM_BOX_SIZE = 320;

const DELETE_STORAGE = false;
const LOCAL = false;
const OFFLINE = false;
const SERVER_ADDR = (!LOCAL) ? "https://belote.ayoubsbai.com" : "http://192.168.0.135:8080";

export { ROOM_BOX_SIZE, DELETE_STORAGE, LOCAL, OFFLINE, SERVER_ADDR };
